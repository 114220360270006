<template>
  <v-dialog v-model="showModal" persistent width="90%">
    <v-card class="pa-md-4 mx-lg-auto">
      <!-- transcript breadcrumb row -->
      <v-row class="breadcrumbs-padding">
          <div class="breadcrumbs-padding">
            <insight-breadcrumbs v-if="context=='insights'"
                :insightHeader="crumbDefHeader"
                :dynaLabelHeader="crumbDynaHeader"
                :singleDynaCount="crumbSingleDyna"
                :searchFieldIsEmpty="crumbSearchIsEmpty"
                :agentHeader="crumbAgentHeader"
                :callId="callId"
                :level="'call-transcript'"
                :useContext="false"
                :linkEnabled="!selfSignedTranscript"
                @go-to-page="selectPage"
              ></insight-breadcrumbs>

              <scorecard-breadcrumbs v-else-if="context=='scorecards' || context=='scorecard_subcategories'"
                :scorecardHeader="crumbDefHeader"
                :dynaLabelHeader="crumbDynaHeader"
                :singleDynaCount="crumbSingleDyna"
                :agentHeader="crumbAgentHeader"
                :callId="callId"
                :level="'call-transcript'"
                :linkEnabled="!selfSignedTranscript"
                @go-to-page="selectPage"
              ></scorecard-breadcrumbs>
          </div>
          <v-spacer ></v-spacer>

          <div style="padding-right: 1rem;">
          <v-btn x-small text icon color="primary" @click="hideModal" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-row>

      <v-card-title class="pa-0 ma-0">
        <h1 class="agent-title">{{ uc(agentName) }}</h1>
      </v-card-title>
      <div>
        <TranscriptHeader
          slot="header"
          :agent="agentName"
          :callerNumber="callerNumber"
          :calledNumber="calledNumber"
          :callDate="callDate"
          :callDirection="callDirection"
          :accountNumber="accountNumber"
          :accountName="accountName"
          :scorecardName="scorecardName"
          :insights="insights"
          :sentiment="sentiment"
          :scorecardId="scorecardId"
          :callId="callId"
          :has_tags="co_has_tags"
          :note_id="co_note_id"
          :note_is_blank="get_co_note_is_blank"
          :playFromPosition="playFromPosition"
          :transcriptExists="transcriptExists"
          :offset="offset"
          :transcriptBtnCounter="transcriptBtnCounter"
          :clientCallId="inAsteriskId"
          :selfSignedTranscript="selfSignedTranscript"
          :menuLocation="getMenuLocation()"
          :isModal="true"
          :showModal="showModal"
          :context="context"
          :contextId="parseInt(contextId)"
          :contextDynalabelId="parseInt(contextDynalabelId)"
          :contextAgentId="parseInt(contextAgentId)"
          :displayedRegions=displayedRegions
          @update-call-note="updateCallNote"
          @update-call-has-tags="updateCallHasTags"
          @update-play-head-position="updatePlayHeadPosition"
          @open-transcript-in-new-tab="openTranscriptInNewTab"
        ></TranscriptHeader>
      </div>
      <div v-if="transcriptExists" class="modal-body">
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <template>
              <v-tabs v-model="activeTab" background-color="primary" dark>
                <v-tab :key="'transcript'" :href="'#tab-transcript'">Transcript</v-tab>
                <v-tab :key="'summary'" :href="'#tab-summary'" v-show="this.summaryIsReady&&!this.summaryIsLoading">Summary</v-tab>
                <template>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-show="userDataLoadingStatus=='loaded' && this.canGenerateCallSummaries && !this.transcriptHasSummary"
                    style="margin-top: .66rem; margin-right: .66rem;"
                    color="warning"
                    small
                    title="Summary Powered By OpenAI"
                    :disabled="summaryIsLoading"
                    :loading="summaryIsLoading"
                    @click="generateCallSummary()"
                  >
                    <custom-icon name='open-ai-mark' style="margin-right: .5rem;"></custom-icon>
                    Get Call Summary
                  </v-btn>
                </template>
              </v-tabs>
              <v-tabs-items v-model="activeTab">

                <v-tab-item :key="'transcript'" :value="'tab-transcript'" style="height: 35vh;">
                  <transcript style="height: 35vh; overflow:scroll;"
                    :transcript="transcriptData"
                    :callIn="callIn"
                    :callId="callId"
                    :eecNameXref="eecNameXref"
                    :custProf="custProf"
                    :playHeadPosition="playHeadPosition"
                    :class="'transcript-modal'"
                    :transcriptions="transcriptions"
                    :selfSignedTranscript="selfSignedTranscript"
                    @update-play-from-position="updatePlayFromPosition"
                  ></transcript>
                </v-tab-item>
                <v-tab-item :key="'summary'" :value="'tab-summary'" >
                  <transcript-summary style="height: 35vh; overflow:scroll;" :summaryData="summaryData" :summaryIsLoading="summaryIsLoading" :summaryIsReady="summaryIsReady"></transcript-summary>
                </v-tab-item>
              </v-tabs-items>
            </template>
          </v-col>
        </v-row>
      </div>
      <div class="modal-body" v-else-if="!transcriptExists && !loadingTranscript">
        <v-card-text>
          Transcript is not available for this call
        </v-card-text>
      </div>
      <div class="modal-body" v-else>
        <v-card-text>
          Transcript is loading...
        </v-card-text>
      </div>
      <div class="modal-footer">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          x-small
          color="primary"
          @click="hideModal"
        >
          Close
        </v-btn>
      </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Transcript from "@/components/Transcripts/Transcript"
import TranscriptSummary from "@/components/Transcripts/TranscriptSummary"
import TranscriptHeader from "@/components/Transcripts/Header"
import {mapGetters} from "vuex"
import axios from "@/utils/AxiosInstance.js"
import selectPage from "@/mixins/selectPage";
import InsightBreadcrumbs from '../Breadcrumbs/InsightBreadcrumbs.vue'
import ScorecardBreadcrumbs from '../Breadcrumbs/ScorecardBreadcrumbs.vue'
import DemoHandler from "@/utils/DemoHandler";

export default {
  name: "TranscriptModal",
  mixins: [selectPage],
  components: {
    Transcript,
    TranscriptHeader,
    TranscriptSummary,
    InsightBreadcrumbs,
    ScorecardBreadcrumbs
  },
  data() {
    return {
      counter: 0,
      activeTab: 'tab-transcript',
      summaryIsReady: false,
      summaryIsLoading: false,
      dialog: false,
      transcriptData: {},
      eecNameXref: {
        name: ""
      },
      callIn: {
        adjusteddatetime: "",
        orignmbr: "",
        acctnmbr: "",
        channel_count: 1,
      },
      custProf: {
        name: "",
        insights: "",
      },
      callOut: "",
      co_note_id: this.note_id,
      co_note_is_blank: this.note_is_blank,
      co_has_tags: this.has_tags,
      playHeadPosition: 0,
      playFromPosition: 0,
      loadingTranscript: true,
      transcriptUrl: "",
      summaryData: {},
      callDataId: this.callId
    }
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    selfSignedTranscript: {
      type: Boolean,
      required: false,
      default: false,
    },
    menuLocation: {
      type: String,
      required: false,
      default: "transcript",
    },
    offset: Number,
    transcriptBtnCounter: Number,
    callId: String,
    note_id: Number,
    note_is_blank: Number,
    has_tags: Boolean,
    transcriptions: Boolean,
    scorecardName: String,
    scorecardId: String,
    context: {
      type: String,
      required: false,
      default: "",
    },
    contextId: {
      type: Number,
      required: false,
      default: 0,
    },
    contextDynalabelId: {
      type: Number,
      required: false,
      default: 0,
    },
    contextAgentId: {
      type: Number,
      required: false,
      default: 0,
    },
    displayedRegions: {
      type: Array,
      default: () => [],
    },
    crumbDefHeader: {
      type: String,
      required: false,
      default: "",
    },
    crumbDynaHeader: {
      type: String,
      required: false,
      default: "",
    },
    crumbAgentHeader: {
      type: String,
      required: false,
      default: "",
    },
    crumbSingleDyna: {
      type: Boolean,
      required: false,
      default: false,
    },
    crumbLevel: {
      type: String,
      required: false,
      default: "",
    },
    crumbSearchIsEmpty: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    showModal: {
      handler: function() {
        if (!this.callId || !this.showModal) return

        this.dialog = true
        let self = this
        self.eecNameXref = null
        let loader = null
        if(this.menuLocation != 'callScorecard' && this.menuLocation != 'printableCallScorecard'){
          loader = this.$loading.show()
        }

        this.callDataId = DemoHandler.determineDemoCall(this.callId);

        self.getTranscriptData()
        self.getIconStatus()

        let call_records_url = process.env.VUE_APP_CALL_RECORDS + this.callId
        let axios_params = {
          params: {
            fields:
            "eecnamexref.name,callin.channel_count,callin.orignmbr,callin.adjusteddatetime,callin.call_direction,callin.acctnmbr,custprof.name,custprof.insights,callout.pots,note.id,note.note,callin.inasteriskid"
          }
        }

        if(this.selfSignedTranscript) {
          call_records_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + this.callId
          axios_params['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey}
          axios_params['params']['route'] = 'call-records'
        }

        axios.get(call_records_url, axios_params)
          .then(response => {
            if (response && response.data) {
              self.callIn = response.data.callin;
              self.eecNameXref = response.data.eecnamexref;
              self.custProf = response.data.custprof;
              self.callOut = response.data.callout;
              if(response.data.note) {
                self.co_note_id = response.data.note.id || null;
                self.co_note_is_blank = (response.data.note.note) ? 0 : 1;
              } else {
                self.co_note_id = null;
                self.co_note_is_blank = 1;
              }
            }
          })
          .finally(() => {
            if(this.menuLocation != 'callScorecard' && this.menuLocation != 'printableCallScorecard'){
              loader.hide();
            }
          })
      },
      immediate: true
    },
  },
  methods: {
    generateCallSummary() {
      this.summaryIsReady = false
      this.summaryIsLoading = true
      let ai_summary_url = process.env.VUE_APP_SUMMARIES + this.callDataId
      let axios_params = {params:{}}
      axios.get(ai_summary_url,axios_params)
        .then(response => {
          if (response && response.data) {
            this.summaryData = response.data
            this.summaryIsReady = true
            this.activeTab = 'tab-summary'
          }
        }).finally(() => {
          this.summaryIsLoading = false
        })
    },
    getMenuLocation() {
      if(this.menuLocation != 'transcript') {
        return this.menuLocation + 'Transcript'
      } else {
        return this.menuLocation
      }
    },
    uc(text) {
      return text.toUpperCase()
    },
    hideModal() {
      this.summaryIsReady = false
      this.summaryIsLoading = false
      this.$root.$emit("KILL_AUDIO")
      this.$emit("HIDE_MODAL", false)
    },
    getIconStatus() {
      let call_object_url = process.env.VUE_APP_CALL_RECORDS + '?call_ids[]='+ this.callId
      let axios_params = {params:{}}
      if(this.selfSignedTranscript) {
        call_object_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
        axios_params['params']['route'] = 'call-records'
        axios_params['params']['call_ids'] = [this.callId]
        axios_params['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey}

      }
      axios.get(call_object_url,axios_params).then(response => {
        if(response && response.data) {
          let callObject = response.data.data[0]
          this.co_has_tags = callObject.has_tags
          this.co_note_id = callObject.note_id
          this.co_note_is_blank = callObject.note_is_blank
        }
      })
    },
    getTranscriptData() {
      this.summaryIsReady = false
      this.loadingTranscript = true
      let transcript_url = process.env.VUE_APP_TRANSCRIPTS + this.callDataId
      let axios_params = {params:{}}
      if(this.selfSignedTranscript) {
        transcript_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + this.callDataId
        axios_params['params']['route'] = 'transcripts'
        axios_params['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey}
      }
      axios.get(transcript_url,axios_params).then(response => {
        if (response && response.data) {
          this.transcriptData = response.data
          if (this.transcriptData.hasOwnProperty('ai_summary')) {
            this.summaryData = this.transcriptData.ai_summary
            this.summaryIsReady = true
          }
        }
      }).finally(() => {
        this.loadingTranscript = false
      })
    },
    updateCallNote(e) {
      this.co_note_id = e.noteId
      this.co_note_is_blank = e.noteIsBlank
      this.$emit('update-call-note', e)
    },

    updateCallHasTags(e) {
      this.co_has_tags = e.has_tags
      this.$emit('update-call-has-tags', e)
    },
    updatePlayHeadPosition(e) {
      this.playHeadPosition = e
    },
    updatePlayFromPosition(e) {
      this.playFromPosition = e
    },
    openTranscriptInNewTab(transcriptLink) {
      window.open(process.env.VUE_APP_BASE_URL + transcriptLink, "_blank")
      this.hideModal()
    },
    selectPage(page) {
      this.$emit("HIDE_MODAL", false)
      if (this.context == 'insights') {
        this.selectInsightPage(page, false, this.$refs)
      } else {
        this.selectScorecardPage(page, false)
      }
    }
  },
  computed: {
    ...mapGetters('dateRange', [
      'start_date',
      'end_date',
    ]),
    ...mapGetters('users', [
      'userData',
      'userDataLoadingStatus',
      'userDataLoadingError',
      'userDataLoadingErrorStatus',
    ]),
    canGenerateCallSummaries() {
      return (this.userDataLoadingStatus == 'loaded' && this.userData.settings.generateCallSummaries)
    },
    transcriptHasSummary() {
      return (this.summaryData.hasOwnProperty('summary'))
    },
    transcriptExists() {
      return (
        this.transcriptData.analyses && this.transcriptData.analyses.length > 0
      );
    },
    get_co_note_is_blank() {
      if(this.co_note_is_blank === undefined)  {
        return 0
      } else {
        return this.co_note_is_blank
      }
    },
    agentName() {
      return this.eecNameXref ? this.eecNameXref.name : ""
    },
    callerNumber() {
      return this.callIn.orignmbr || ""
    },
    calledNumber() {
      return this.callOut.pots || ""
    },
    callDate() {
      return this.callIn.adjusteddatetime || ""
    },
    callDirection() {
      return this.callIn.call_direction || ""
    },
    accountName() {
      return this.custProf.name || ""
    },
    accountNumber() {
      return Number(this.callIn.acctnmbr) || 0
    },
    insights() {
      return Boolean(this.custProf.insights == 1)
    },
    sentiment() {
      if (!this.transcriptData.analyses || !this.transcriptData.analyses[0]) {
        return ""
      }
      return this.transcriptData.analyses[0].sentiment || ""
    },
    inAsteriskId() {
      return this.callIn.inasteriskid || ""
    },
  },
}
</script>
<style scoped>

  h1.agent-title {
    font-family: "Roboto";
    font-size: 1.2rem;
    padding-left: .8rem;
    color: rgba(1, 106, 175, 1);
    line-height: 1.4rem;
  }

  >>> .v-dialog {
    overflow-y: hidden;
  }

  div.breadcrumbs-padding {
    padding-left: .8rem;
  }

</style>
