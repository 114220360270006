//this file uses presentation-service to format fields in the UI
import emotion from "@/mixins/emotion";
const DECIMAL = ".";
const PERCENT = "%";
const MM_SS = "mm:ss";
const FRACTION = "/";

export default {
  mixins: [emotion],
  methods: {
    getName(
      info,
      grouping,
      usedByAgent,
      rowName,
      usingInsights,
      displayFormat
    ) {
      //gets the row name and format
      if (usingInsights && grouping) {
        if (displayFormat == "First Last") {
          return usedByAgent
            ? info.agent.name
            : info.title;
        } else {
          return usedByAgent ? info.agent.name : info.title;
        }
      } else if (!usingInsights && grouping) {
        if (displayFormat == "First Last") {
          return usedByAgent
            ? info.agentName
            : info.dynalabelTitle;
        } else {
          return usedByAgent ? info.agentName : info.dynalabelTitle;
        }
      }

      return rowName;
    },
    insightsPresentationFormattedData(
      obj,
      grouping,
      channelCount,
      usedByAgent
    ) {
      //this function is used by agent and dynamic label levels of insights - it takes the display formats from presenation-service and uses them to format the data on the UI
      //insights variables
      let rowName = "View All Dynamic Labels";
      let agentName = "View All Calls";
      let dataString = "dynalabel_insights[]";
      let rowId = 0;
      if (grouping) {
        rowId = usedByAgent ? obj.agent_id : obj.dynalabel_id;
      }
      if (usedByAgent) {
        rowName = "View All Agents"
        dataString = "agent_insights[]";
      }
      let averageDuration = obj.average_duration;
      let averageDuration_formatted = obj.average_duration;
      let sentimentCounts = {};
      let emotionCounts = {};
      let silenceAveragePercent = obj.silence.average_percent;
      let silencePercentCustomer = obj.silence.percent_customer;
      let silencePercentAgent = obj.silence.percent_agent;
      let silenceAverageSeconds = obj.silence.average_seconds;
      let talkTimeAveragePercent = obj.talktime.average_percent;
      let talkTimeAverageSeconds = obj.talktime.average_seconds;
      let talkTimePercentAgent = obj.talktime.percent_agent;
      let talkTimePercentCustomer = obj.talktime.percent_customer;
      let overtalkAveragePercent = obj.overtalk.average_percent;
      let overtalkAverageSeconds = obj.overtalk.average_seconds;
      let overtalkPercentAgent = obj.overtalk.percent_agent;
      let overtalkPercentCustomer = obj.overtalk.percent_customer;
      let totalTalkTimeSeconds = talkTimeAverageSeconds;
      let overtalkRatioString = "";
      let talktimeRatioString = "";
      let silenceRatioString = "";
      let callCount = obj.call_count;
      //loop through our display format data from presentation-service and match the fields with fields used in insights - format the field based on the display and data_type from presentation-service
      //this.displayFormats is a vue store property imported in the insights.vue files
      this.displayFormats.forEach(display => {
        let key = Object.keys(display)[0];
        let dataType = display[key].data_type;
        let displayFormat = display[key].display;
        switch (key) {
          case "agent.name":
            rowName = this.getName(
              obj,
              grouping,
              usedByAgent,
              rowName,
              true,
              displayFormat
            );
            agentName = rowName;
            break;
          case "average_duration":
            averageDuration_formatted = this.formatField(
              averageDuration_formatted,
              dataType,
              displayFormat
            );
            break;
          case "call_count":
            callCount = this.formatField(callCount, dataType, displayFormat);
            break;
          case "sentiment_counts.customer_sentiment_counts||" +
            dataString +
            ".sentiment_counts.overall_sentiment_counts":
            if (channelCount == 2) {
              sentimentCounts = this.gatherSubjectiveNumbers(
                obj.sentiment_counts,
                obj.call_count,
                "customer_sentiment_counts"
              );
            } else {
              sentimentCounts = this.gatherSubjectiveNumbers(
                obj.sentiment_counts,
                obj.call_count,
                "overall_sentiment_counts"
              );
            }
            break;
          case "emotion_counts.customer_emotion_counts||" +
            dataString +
            ".emotion_counts.overall_emotion_counts":
            if (channelCount == 2) {
              emotionCounts = this.gatherSubjectiveNumbers(
                obj.emotion_counts,
                obj.call_count,
                "customer_emotion_counts"
              );
            } else {
              emotionCounts = this.gatherSubjectiveNumbers(
                obj.emotion_counts,
                obj.call_count,
                "overall_emotion_counts"
              );
            }
            break;
          case "silence.percent_agent/" +
            dataString +
            ".silence.percent_customer":
            silenceRatioString = this.formatRatio(
              dataType,
              displayFormat,
              silencePercentAgent,
              silencePercentCustomer
            );
            break;
          case "overtalk.percent_agent/" +
            dataString +
            ".overtalk.percent_customer":
            overtalkRatioString = this.formatRatio(
              dataType,
              displayFormat,
              overtalkPercentAgent,
              overtalkPercentCustomer
            );
            break;
          case "talktime.percent_agent/" +
            dataString +
            ".talktime.percent_customer":
            talktimeRatioString = this.formatRatio(
              dataType,
              displayFormat,
              talkTimePercentAgent,
              talkTimePercentCustomer
            );
            break;
          case "silence.average_percent":
            silenceAveragePercent = this.formatField(
              silenceAveragePercent,
              dataType,
              displayFormat
            );
            break;
          case "silence.percent_agent":
            silencePercentAgent = this.formatField(
              silencePercentAgent,
              dataType,
              displayFormat
            );
            break;
          case "silence.percent_customer":
            silencePercentCustomer = this.formatField(
              silencePercentCustomer,
              dataType,
              displayFormat
            );
            break;
          case "talktime.total_percent":
            talkTimeAveragePercent = this.formatField(
              talkTimeAveragePercent,
              dataType,
              displayFormat
            );
            break;
          case "talktime.percent_agent":
            talkTimePercentAgent = this.formatField(
              talkTimePercentAgent,
              dataType,
              displayFormat
            );
            break;
          case "talktime.percent_customer":
            talkTimePercentCustomer = this.formatField(
              talkTimePercentCustomer,
              dataType,
              displayFormat
            );
            break;
          case "overtalk.total_percent":
            overtalkAveragePercent = this.formatField(
              overtalkAveragePercent,
              dataType,
              displayFormat
            );
            break;
          case "overtalk.average_seconds":
            overtalkAverageSeconds = this.formatField(
              overtalkAverageSeconds,
              dataType,
              displayFormat
            );
            break;
          case "overtalk.percent_agent":
            overtalkPercentAgent = this.formatField(
              overtalkPercentAgent,
              dataType,
              displayFormat
            );
            break;
          case "overtalk.percent_customer":
            overtalkPercentCustomer = this.formatField(
              overtalkPercentCustomer,
              dataType,
              displayFormat
            );
            break;
          case "talktime.average_seconds":
            talkTimeAverageSeconds = this.formatField(
              talkTimeAverageSeconds,
              dataType,
              displayFormat
            );
            break;
          case "average_duration-" + dataString + ".talktime.average_seconds":
            //silence seconds = call duration - talktime...
            silenceAverageSeconds = this.formatField(
              averageDuration - totalTalkTimeSeconds,
              dataType,
              displayFormat
            );
            break;
          default:
            break;
        }
      });

      //return the insight row
      return {
        name: rowName,
        agent: agentName,
        id: rowId,
        channelCount: channelCount,
        singleChannelCallCount: obj.single_channel_call_count,
        dualChannelCallCount: obj.dual_channel_call_count,
        overallCount: obj.overall_count,
        callCount: callCount,
        averageDuration: averageDuration,
        averageDuration_formatted: averageDuration_formatted,
        sentimentCounts: sentimentCounts,
        emotionCounts: emotionCounts,
        silenceAveragePercent: silenceAveragePercent,
        silenceAverageSeconds: silenceAverageSeconds,
        silencePercentAgent: silencePercentAgent,
        silencePercentCustomer: silencePercentCustomer,
        talkTimeAveragePercent: talkTimeAveragePercent,
        talkTimeAverageSeconds: talkTimeAverageSeconds,
        talkTimePercentAgent: talkTimePercentAgent,
        talkTimePercentCustomer: talkTimePercentCustomer,
        overtalkAveragePercent: overtalkAveragePercent,
        overtalkAverageSeconds: overtalkAverageSeconds,
        overtalkPercentAgent: overtalkPercentAgent,
        overtalkPercentCustomer: overtalkPercentCustomer,
        silenceRatio: silenceRatioString,
        overtalkRatio: overtalkRatioString,
        talktimeRatio: talktimeRatioString
      };
    },
    scorecardsPresentationFormattedData(
      obj,
      categories,
      grouping,
      usedByAgent
    ) {
      //this function is used by agent and dynamic label levels of scorecards - it takes the display formats from presenation-service and uses them to format the data on the UI
      //scorecards data fields
      let row = {};
      let rowName = "View All Dynamic Labels"
      if (usedByAgent) {
        rowName = "View All Agents";
      }
      let handleTime = obj.metrics.averageDuration;
      let callScore = obj.metrics.callScoreAvg;
      let callVolume = obj.metrics.averageCallVolume;
      let totalCalls = obj.metrics.totalCalls;
      let callsModified = obj.metrics.callsModified;
      let categoryDisplay = "";
      let categoryDataType = "";
      //loop through our display format data from presentation-service and match the fields with fields used in scorecards - format the field based on the display and data_type from presentation-service
      //this.displayFormats is a vue store property imported in the scorecards.vue files
      this.displayFormats.forEach(display => {
        let key = Object.keys(display)[0];
        let dataType = display[key].data_type;
        let displayFormat = display[key].display;
        switch (key) {
          case "agentName":
          case "dynalabelTitle":
            rowName = this.getName(
              obj,
              grouping,
              usedByAgent,
              rowName,
              false,
              displayFormat
            );
            break;
          case "averageDuration":
            handleTime = this.formatField(handleTime, dataType, displayFormat);
            break;
          case "averageCallVolume":
            callVolume = this.formatField(callVolume, dataType, displayFormat);
            break;
          case "totalCalls":
            totalCalls = this.formatField(totalCalls, dataType, displayFormat);
            break;
          case "categories[]":
            categoryDisplay = displayFormat;
            categoryDataType = dataType;
            break;
          default:
            break;
        }
      });

      row.name = rowName;
      row.average_handle_time = handleTime;
      row.average_duration = obj.metrics.averageDuration
      row.average_call_score = callScore;
      row.average_call_volume = callVolume;
      row.call_count = totalCalls;
      row.calls_modified = callsModified;
      row.excluded_calls = obj.metrics.excludedCallCount
      categories.forEach(category => {
        row[category.id] = this.formatField(
          category.score,
          categoryDataType,
          categoryDisplay
        );
        row[category.id + "_calls_modified"] = category.calls_modified;
        row[category.id + "_num_not_applicable"] = category.num_not_applicable;
        row[category.id + "_total_calls"] = category.total_calls;
      });
      row.id = grouping ? obj.id : 0;

      //return the scorecard row
      return row;
    },
    formatPercentFloat(data, decimalNum) {
      return this.thousands(this.rounded(data, decimalNum));
    },
    formatPercentInt(data) {
      data = data ? data : 0; // prevents NaN
      return Math.round(data * 100) + PERCENT;
    },
    formatInt(data) {
      data = data ? data : 0; // prevents NaN
      return Math.round(data);
    },
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
    formatField(currentData, dataType, displayFormat) {
      //formats a field based on the presentation data type and display
      switch (dataType) {
        case "float":
          if (displayFormat.includes(PERCENT)) {
            if (displayFormat.includes(DECIMAL)) {
              currentData = this.formatPercentFloat(currentData, 1);
            } else {
              currentData = this.formatPercentInt(currentData);
            }
          } else if (displayFormat.includes(DECIMAL)) {
            currentData = this.formatPercentFloat(currentData, 1);
          } else if (displayFormat == MM_SS) {
            currentData = this.formatHMS(this.rounded(currentData, 0));
          } else {
            currentData = this.formatInt(currentData);
          }
          break;
        default:
          break;
      }
      return currentData;
    },
    formatRatio(dataType, displayFormat, numerator, denominator) {
      //formats the ratio for silence/overtalk/speaking time in insights
      if (displayFormat.includes(FRACTION)) {
        if (dataType == "float") {
          numerator = Math.round(numerator * 100);
          denominator = Math.round(denominator * 100);
          return "(" + numerator + FRACTION + denominator + ")";
        }
      }
      return currentData;
    },
    thousands(num) {
      return Number(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    rounded(num, places) {
      return Number(num).toFixed(places);
    },
  }
};
