<template>
  <div>
    <template v-if="transcriptions" class="float-right   ">
      <v-icon v-if="displaySoup"
        class="float-right pl-2"
        style="cursor: pointer;"
        @click.native.stop="loadModal"
        title="Open Transcript..."
      >
        mdi-message-text-outline
      </v-icon>
    </template>
    <template v-else class="float-right  ">
      <v-icon v-if="displaySoup"
        class="float-right icon-disabled"
        title="Transcript access is not enabled for this user/account"
      >
        mdi-message-text-outline
      </v-icon>
    </template>
    <download-p-d-f-control
      :printable_url="getPrintableURL()"
      :scorecardId="scorecardId"
      :displaySoup="displaySoup"
    ></download-p-d-f-control>
    <email-control ref="emailControl"
      :url="getURL()"
      :printable_url="getPrintableURL()"
      :selfSignedTranscript="selfSigned"
      :feature="'scorecards'"
      :dialog_title="'Call Scorecard'"
      :reporttype="'call-scorecard'"
      :scorecardId="scorecardId"
      :callId="callId"
      :start_date="start_date"
      :end_date="end_date"
      :icon_position="' float-right'"
      :type="'call'"
      :displaySoup="displaySoup"
      @overlay-present="overlayPresent"
    ></email-control>
    <tags-edit-control ref="tagsEditControl"
      v-if="userDataLoadingStatus == 'loaded'"
      class="float-right pl-1"
      @update-call-has-tags="updateCallHasTags"
      :selfSignedTranscript="selfSigned"
      :tagPriv="returnSignedPrivileges(userData.privileges.tags)"
      :callId="Number(callId)"
      :hasTags="Boolean(callData.has_tags)"
      :displaySoup="displaySoup"
    ></tags-edit-control>
    <note-edit-control ref="notesEditControl"
      v-if="userDataLoadingStatus == 'loaded'"
      class="float-right pl-1"
      @update-call-note="updateCallNote"
      @overlay-present="overlayPresent"
      :selfSignedTranscript="selfSigned"
      :notePriv="returnSignedPrivileges(userData.privileges.notes)"
      :callId="Number(callId)"
      :noteId="callData.note_id"
      :noteIsBlank="callData.note_is_blank"
      :displaySoup="displaySoup"
    ></note-edit-control>
    <custom-metadata-modal ref="customMetadata"
      @custom-metadata-disabled="disabledCustomMetadata"
      :selfSignedTranscript="selfSigned"
      class=" float-right pr-1 pl-1"
      :callId="callId"
      :displaySoup="displaySoup"
    ></custom-metadata-modal>
    <transcript-menu v-if="userDataLoadingStatus=='loaded'"
      @menuClick="menuClick"
      :isLegacy="false"
      :tagPriv="returnSignedPrivileges(userData.privileges.tags)"
      :hasTags="Boolean(callData.has_tags)"
      :notePriv="returnSignedPrivileges(userData.privileges.notes)"
      :noteIsBlank="callData.note_is_blank"
      :menuLocation="menuLocation"
      :selfSignedTranscript="selfSigned"
      :customMetadataDisabled="customMetadataDisabled"
      :transcriptProcessStatus="transcriptProcessStatus"
      :transcriptExports="userData.settings.transcriptExports"
      :transcriptions="transcriptions"
      :displaySoup="displaySoup"
    ></transcript-menu>
  </div>
</template>

<script>
import downloadPDFControl from "@/components/Controls/DownloadPDFControl.vue";
import emailControl from "@/components/Controls/EmailControl.vue";
import noteEditControl from "@/components/Controls/NoteEditControl";
import tagsEditControl from "@/components/Controls/TagsEditControl";
import CustomMetadataModal from "@/components/Widgets/CustomMetadataModal.vue";
import TranscriptMenu from "@/components/Widgets/TranscriptMenu.vue";
import axios from "@/utils/AxiosInstance.js"

import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    CustomMetadataModal,
    tagsEditControl,
    noteEditControl,
    emailControl,
    downloadPDFControl,
    TranscriptMenu
  },
  props: {
    callId: String,
    scorecardId: String,
    dynalabelId: String,
    agentId: String,
    callData: Object,
    transcriptions: Boolean,
    selfSigned: {
      type: Boolean,
      required: false,
      default: false,
    },
    menuLocation: {
        type: String,
        required: true,
        default: 'callScorecard'
    },
    displaySoup: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data() {
    return {
      customMetadataDisabled: false,
      transcriptProcessStatus: false,
      callScorecardUrl: "",
    };
  },
  computed: {
    ...mapGetters("users", ["userData", "userDataLoadingStatus"]),
    ...mapGetters("dateRange", ["start_date", "end_date"]),
  },
  methods: {
    getURL() {
      let url =
        "/scorecards-v2/" +
        this.scorecardId +
        "/dynalabels/" +
        this.dynalabelId +
        "/agents/" +
        this.agentId +
        "/calls/?start_date=" +
        moment(this.start_date).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(this.end_date).format("YYYY-MM-DD") +
        "#call-scorecard-" +
        this.callId;
      return url;
    },
    disabledCustomMetadata(disabled) {
      this.customMetadataDisabled = disabled
    },
    transcriptDownloadSetter(processStatus) {
      this.transcriptProcessStatus = processStatus
    },
    getPrintableURL() {
      let url =
        "/scorecards-v2/" +
        this.scorecardId +
        "/dynalabels/" +
        this.dynalabelId +
        "/agents/" +
        this.agentId +
        "/calls/" +
        this.callId +
        "/printable-call-scorecard" +
        "?start_date=" +
        moment(this.start_date).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(this.end_date).format("YYYY-MM-DD");
      url += this.$store.getters["filters/scorecardAdjustments"]
        ? "&call_type=" + this.$store.getters["filters/scorecardAdjustments"]
        : "all";
      return url;
    },
    overlayPresent(e) {
      this.$emit("overlay-present", e)
    },
    updateCallNote(e) {
      this.callData.note_is_blank = Number(e.note_is_blank)
      this.$emit("update-call-note", e);
    },
    updateCallHasTags(e) {
      this.callData.has_tags = e.has_tags
      this.$emit("update-call-has-tags", e);
    },
    loadModal() {
      this.$emit("loadModal");
    },
    menuClick(item) {
      switch (item) {
        case "customMetadataClick":
          this.$refs.customMetadata.openDialog()
          break;
        case "notesClick":
          if(this.userData.privileges.notes !== "NONE") {
            this.$refs.notesEditControl.toggleDialog()
          }
          break;
        case "tagsClick":
          if(this.userData.privileges.tags !== "NONE") {
            this.$refs.tagsEditControl.openDialog()
          }
          break;
        case "sendEmailClick":
          if(!this.selfSigned) {
            if(this.callScorecardUrl == "") {
              axios
                .post(process.env.VUE_APP_RECKEYS,{
                  call_id: this.callId,
                  expire: true,
                }).then(response => {
                  this.callScorecardUrl = process.env.VUE_APP_BASE_URL + "/scorecards-v2-signed/" + this.$route.params.scorecardId + "/dynalabels/" + this.$route.params.dynalabelId + "/agents/" + this.$route.params.agentId + "/calls/" + this.callId + "/printable-call-scorecard/" + response.data.reckey + "/" + this.userData.id + "?start_date=" + moment(this.$route.query.start_date).format("YYYY-MM-DD") + "&end_date=" + moment(this.$route.query.end_date).format("YYYY-MM-DD")
                  this.callScorecardUrl += this.$store.getters["filters/scorecardAdjustments"]
                    ? "&call_type=" + this.$store.getters["filters/scorecardAdjustments"]
                    : "all";
                  this.$refs.emailControl.openDialogWithUrl(this.callScorecardUrl)
                })
            } else {
              this.$refs.emailControl.toggleDialog()
            }
          }
          break;
        case "exportTranscriptClick":
          this.$refs.transcriptDownload.exportTranscript()
          break;
        case "openTranscriptsClick":
          if(this.transcriptions) {
            this.loadModal()
          }
          break;
        case "printPageClick":
          break;
      }
    },
    returnSignedPrivileges(privilege) {
      if(this.selfSigned && privilege !== "NONE") {
        return "READ"
      } else {
        return privilege;
      }
    },
  },
  mounted() {},
  watch: {}
};
</script>

<style></style>
