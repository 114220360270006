<template>
  <v-container fluid pa-0>

    <!-- transcript breadcrumb row -->
    <div v-if="contextId != 0 && contextAvailable" class="breadcrumbs">

      <insight-breadcrumbs
        v-if="context == 'insights'"
        :insightHeader="definitionHeader"
        :dynaLabelHeader="dynalabelHeader"
        :singleDynaCount="singleDynaCount"
        :agentHeader="agentHeader"
        :callId="callId"
        :searchFieldIsEmpty="false"
        :level="'call-transcript'"
        :useContext="true"
        :linkEnabled="!selfSignedTranscript"
        @go-to-page="selectPage"
      ></insight-breadcrumbs>

      <scorecard-breadcrumbs
        v-else-if="context == 'scorecards'"
        :scorecardHeader="definitionHeader"
        :dynaLabelHeader="dynalabelHeader"
        :singleDynaCount="singleDynaCount"
        :agentHeader="agentHeader"
        :callId="callId"
        :level="'call-transcript'"
        :linkEnabled="!selfSignedTranscript"
        @go-to-page="selectPage"
      ></scorecard-breadcrumbs>
    </div>
    <TranscriptHeader
      v-slot="header"
      :agent="agentName"
      :callerNumber="callerNumber"
      :callDate="callDate"
      :callDirection="callDirection"
      :accountNumber="accountNumber"
      :accountName="accountName"
      :scorecardName="scorecardName"
      :insights="insights"
      :sentiment="sentiment"
      :scorecardId="scorecardId"
      :callId="callId"
      :has_tags="co_has_tags"
      :note_id="co_note_id"
      :note_is_blank="co_note_is_blank"
      :playFromPosition="playFromPosition"
      :transcriptExists="transcriptExists"
      :offset="offset"
      :transcriptBtnCounter="transcriptBtnCounter"
      :clientCallId="inAsteriskId"
      :noModal="true"
      :type="$route.params.type"
      :calledNumber="calledNumber"
      :selfSignedTranscript="selfSignedTranscript"
      :context="context"
      :contextId="parseInt(contextId)"
      :contextDynalabelId="parseInt(contextDynalabelId)"
      :contextAgentId="parseInt(contextAgentId)"
      @update-call-note="updateCallNote"
      @update-call-has-tags="updateCallHasTags"
      @update-play-head-position="updatePlayHeadPosition"
    ></TranscriptHeader>
    <div v-if="transcriptExists">
      <transcript
        :style="getStyle"
        :transcript="transcriptData"
        :callIn="callIn"
        :callId="callId"
        :eecNameXref="eecNameXref"
        :custProf="custProf"
        :playHeadPosition="playHeadPosition"
        :class="'transcript-modal'"
        :transcriptions="transcriptions"
        @update-play-from-position="updatePlayFromPosition"
      ></transcript>
    </div>
    <div class="modal-body" v-else-if="!transcriptExists && !loadingTranscript">
      <v-card-text>
        Transcript is not available for this call
      </v-card-text>
    </div>
    <div class="modal-body" v-else>
      <v-card-text>
        Transcript is loading...
      </v-card-text>
    </div>
  </v-container>
</template>

<script>
import Transcript from "@/components/Transcripts/Transcript";
import TranscriptHeader from "@/components/Transcripts/Header";
import axios from "@/utils/AxiosInstance.js";
import selectPage from "@/mixins/selectPage";
import InsightBreadcrumbs from "../Breadcrumbs/InsightBreadcrumbs.vue";
import ScorecardBreadcrumbs from "../Breadcrumbs/ScorecardBreadcrumbs.vue";
import { mapGetters, mapActions } from "vuex";
import DemoHandler from '@/utils/DemoHandler';

export default {
  name: "TranscriptModal",
  mixins: [selectPage],
  components: {
    Transcript,
    TranscriptHeader,
    InsightBreadcrumbs,
    ScorecardBreadcrumbs,
    DemoHandler
  },
  data() {
    return {
      dialog: false,
      transcriptData: {},
      eecNameXref: {
        name: ""
      },
      callIn: {
        adjusteddatetime: "",
        orignmbr: "",
        acctnmbr: "",
        channel_count: 1
      },
      custProf: {
        name: "",
        insights: ""
      },
      callOut: "",
      co_note_id: this.note_id,
      co_note_is_blank: this.note_is_blank,
      co_has_tags: this.has_tags,
      playHeadPosition: 0,
      playFromPosition: 0,
      loadingTranscript: true
    };
  },
  props: {
    viewTranscript: {
      type: Boolean,
      default: false
    },
    selfSignedTranscript: {
      type: Boolean,
      required: false,
      default: false
    },
    offset: Number,
    transcriptBtnCounter: Number,
    callId: String,
    note_id: Number,
    note_is_blank: Number,
    has_tags: Boolean,
    transcriptions: Boolean,
    scorecardName: String,
    scorecardId: String,
    context: {
      type: String,
      required: false,
      default: ""
    },
    contextId: {
      type: Number,
      required: false,
      default: 0
    },
    contextDynalabelId: {
      type: Number,
      required: false,
      default: 0
    },
    contextAgentId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  watch: {
    viewTranscript: {
      handler: function() {
        if (!this.callId || !this.viewTranscript) return;
        this.dialog = true;
        let self = this;
        self.eecNameXref = null;
        let loader = this.$loading.show();

        if (this.userDataLoadingStatus=='loaded') self.getTranscriptData()
        self.getIconStatus()

        let call_records_url = process.env.VUE_APP_CALL_RECORDS + this.callId;
        let axios_params = {
          params: {
            fields:
              "eecnamexref.name,callin.channel_count,callin.orignmbr,callin.adjusteddatetime,callin.call_direction,callin.acctnmbr,custprof.name,custprof.insights,callout.pots,callin.inasteriskid"
          }
        };

        if (this.selfSignedTranscript) {
          call_records_url =
            process.env.VUE_APP_AUTHENTICATE_ROUTE + this.callId;
          axios_params["headers"] = {
            Authorization:
              this.$route.params.userid + " " + this.$route.params.reckey
          };
          axios_params["params"]["route"] = "call-records";
        }

        axios
          .get(call_records_url, axios_params)
          .then(response => {
            if (response && response.data) {
              self.callIn = response.data.callin;
              self.eecNameXref = response.data.eecnamexref;
              self.custProf = response.data.custprof;
              self.callOut = response.data.callout;
            }
          })
          .finally(() => {
            loader.hide();
          });
      },
      immediate: true
    },
    userDataLoadingStatus: function(status) {
      if(status=='loaded') {
        this.getTranscriptData()
      }
    },
  },
  methods: {
    ...mapActions({
      retrieveInsightCallContext: "insights/retrieveInsightCallContext",
      retrieveScorecardCallContext: "scorecardsV2Calls/retrieveScorecardCallContext"
    }),
    hideModal() {
      this.$root.$emit("KILL_AUDIO");
      this.$emit("HIDE_MODAL", false);
    },
    getIconStatus() {
      let call_object_url =
        process.env.VUE_APP_CALL_RECORDS + "?call_ids[]=" + this.callId;
      let axios_params = { params: {} };
      if (this.selfSignedTranscript) {
        call_object_url = process.env.VUE_APP_AUTHENTICATE_ROUTE;
        axios_params["params"]["route"] = "call-records";
        axios_params["params"]["call_ids"] = [this.callId];
        axios_params["headers"] = {
          Authorization:
            this.$route.params.userid + " " + this.$route.params.reckey
        };
      }
      axios.get(call_object_url, axios_params).then(response => {
        if (response && response.data) {
          let callObject = response.data.data[0];
          this.co_has_tags = callObject.has_tags;
          this.co_note_id = callObject.note_id;
          this.co_note_is_blank = callObject.note_is_blank;
        }
      })
    },
    getTranscriptData() {
      let callDataId = DemoHandler.determineDemoCall(this.callId)
      let transcript_url = process.env.VUE_APP_TRANSCRIPTS + callDataId

      let axios_params = {params:{}}
      if(this.selfSignedTranscript) {
        transcript_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + callDataId
        axios_params['params']['route'] = 'transcripts'
        axios_params['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey}
      }

      axios.get(transcript_url,axios_params).then(response => {
        if (response.data && response.data) {
          this.transcriptData = response.data;
        }
      }).finally(() => {
        this.loadingTranscript = false;
      });
    },
    updateCallNote(e) {
      this.co_note_id = e.noteId;
      this.co_note_is_blank = e.note_is_blank ? 1 : 0; // expecting a number not a boolean
      this.$emit("update-call-note", e);
    },
    updateCallHasTags(e) {
      this.co_has_tags = e.has_tags;
      this.$emit("update-call-has-tags", e);
    },
    updatePlayHeadPosition(e) {
      this.playHeadPosition = e;
    },
    updatePlayFromPosition(e) {
      this.playFromPosition = e;
    },
    selectPage(page) {
      if (this.context == "insights") {
        this.selectInsightPage(page, true, this.$refs);
      } else {
        this.selectScorecardPage(page, "transcript");
      }
    }
  },
  computed: {
    ...mapGetters("insights", [
      "insightCallContext",
      "insightCallContextLoadingStatus",
      "insightCallContextLoadingError",
      "insightCallContextLoadingErrorStatus"
    ]),
    ...mapGetters("scorecardsV2Calls", [
      "scorecardCallContext",
      "scorecardCallContextLoadingStatus",
      "scorecardCallContextLoadingError",
      "scorecardCallContextLoadingErrorStatus"
    ]),
    ...mapGetters('users', [
      'userData',
      'userDataLoadingStatus',
      'userDataLoadingError',
      'userDataLoadingErrorStatus',
    ]),
    contextAvailable() {
      return this.context == "insights"
        ? this.callHasInsightContext
        : this.callHasScorecardContext;
    },
    callHasInsightContext() {
      return (this.insightCallContextLoadingStatus == "loaded" && this.insightCallContext.hasOwnProperty("insight_title"));
    },
    callHasScorecardContext() {
      return (this.scorecardCallContextLoadingStatus == "loaded" && this.scorecardCallContext.hasOwnProperty("scorecard_title"));
    },
    definitionHeader() {
      return (this.context == "insights") ? this.insightCallContext.insight_title : this.scorecardCallContext.scorecard_title
    },
    dynalabelHeader() {
      return (this.context == "insights") ? this.insightCallContext.dynalabel_title : this.scorecardCallContext.dynalabel_title
    },
    agentHeader() {
      return (this.context == "insights") ? this.insightCallContext.agent_name : this.scorecardCallContext.agent_name
    },
    singleDynaCount() {
      return (this.context == "insights") ? this.insightCallContext.dynalabel_count < 2 : this.scorecardCallContext.dynalabel_count < 2
    },
    getStyle() {
      if (this.selfSignedTranscript) {
        return "height: 50vh; overflow:scroll;";
      } else {
        return "height: 40vh; overflow:scroll;";
      }
    },
    transcriptExists() {
      if (JSON.stringify(this.transcriptData) === "{}") {
        return false;
      } else {
        return true;
      }
    },
    agentName() {
      return this.eecNameXref ? this.eecNameXref.name : "";
    },
    callerNumber() {
      return this.callIn.orignmbr || "";
    },
    calledNumber() {
      return this.callOut.pots || "";
    },
    callDate() {
      return this.callIn.adjusteddatetime || "";
    },
    callDirection() {
      return this.callIn.call_direction || "";
    },
    accountName() {
      return this.custProf.name || "";
    },
    accountNumber() {
      return Number(this.callIn.acctnmbr) || 0;
    },
    insights() {
      return Boolean(this.custProf.insights == 1);
    },
    sentiment() {
      if (!this.transcriptData.analyses || !this.transcriptData.analyses[0]) {
        return "";
      }
      return this.transcriptData.analyses[0].sentiment || "";
    },
    inAsteriskId() {
      return this.callIn.inasteriskid || "";
    }
  },
  mounted() {
    //0 is the default context id if none exist
    if (this.contextId != 0) {
      let axios_params = {
        params: {
          context: true,
        }
      }
      
      axios_params["params"]["call_id"] = this.callId;
      axios_params["params"]["dynalabel_id"] = this.contextDynalabelId;
      axios_params["params"]["agent_id"] = this.contextAgentId;

      if(this.context == "insights") {
        axios_params["params"]["insight_id"] = this.contextId;
      } else {
        axios_params["params"]["scorecard_id"] = this.contextId;
      }

      if(this.selfSignedTranscript) {
        axios_params["selfSigned"] = true;
        axios_params["headers"] = {
          Authorization:
            this.$route.params.userid + " " + this.$route.params.reckey
        }
        if(this.context == "insights") {
          axios_params["params"]["route"] = "insight_calls";
        } else {
          axios_params["params"]["route"] = "call_scorecards_v2_url";
        }
      }

      if (this.context == "insights") {
        this.retrieveInsightCallContext(axios_params);
      } else {
        this.retrieveScorecardCallContext(axios_params);
      }
    }
  }
};
</script>
<style scoped>
>>> .v-dialog {
  overflow-y: hidden;
}

div.breadcrumbs {
  padding-left: 0.8rem;
}
</style>
