<template>
  <div>
    <v-menu
      v-model="showMenu"
      absolute
      offset-y
      :position-x="menuPosition.x"
      :position-y="menuPosition.y"
      style="max-width: 400px"
    >
      <v-list elevation="1">
        <v-list-item sm @click="exportInsights">
          <v-list-item-action sm>
            <v-icon
              title="Add to export queue..."
            >mdi-export
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            Export
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      Insights export is processing
        <v-btn color="blue" text @click="snackbar = false">
          Close
        </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/utils/AxiosInstance.js";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    insightsId: {
      type: Number,
      required: true
    },
    insightsUrl: {
      type: String,
      required: true
    },
    insightsTitle: {
      type: String
    },
    dateRange: {
      type: String
    },
  },
  data: () => ({
    processChecker: 0,
    processing: false,
    date: new Date(),
    guid: '',
    interval: {},
    value: 0,
    timerStarted: false,
    snackbar: false,
    timeout: 5000,
    showMenu: false,
    menuPosition: {
      x: 0,
      y: 0
    },
  }),
  computed: {
    ...mapGetters("notifications", [
      "notificationsList",
      "notificationsListLoadingStatus",
      "notificationsListLoadingError",
      "notificationsListLoadingErrorStatus"
    ]),
    ...mapGetters("users", ["userData", "userDataLoadingStatus"]),
    userId() {
      return this.userData.id;
    },
    processStatus() {
      if (this.notificationsList.find(x => x.id === this.insightsId) == undefined) {
        this.value = 0;
      }
      return this.notificationsList.find(x => x.id === this.insightsId) == undefined
        ? false
        : this.notificationsList.find(x => x.id === this.insightsId).processing;
    }
  },
  methods: {
    startTimer() {
      this.interval = setInterval(() => {
        this.value += 10;
        if (this.value == 30) {
          this.timerStarted = false;
          clearInterval(this.interval);
        }
      }, 1000);
    },
    ...mapActions({
      updateNotificationsList: "notifications/updateNotificationsList",
      removeNotificationsListItem: "notifications/removeNotificationsListItem"
    }),
    checkCompletion() {
      let params = {
        id: this.insightsId,
        guid: this.guid,
        processing: true,
        user_id: this.userId,
        date: this.date,
        url: "",
        title: this.insightsTitle,
        dateRange: this.dateRange,
        type: "insights"
      };
      this.updateNotificationsList(params);
    },
    exportInsights(orderBy,orderDirection) {
      this.snackbar = true;
      this.timerStarted = true;
      this.processing = true;
      this.startTimer();

      let param_insights_url = this.insightsUrl      
      if(orderBy != null && orderDirection != null) {
        param_insights_url += "&order_by=" + orderBy + "&order_direction=" + orderDirection
      }

      let parameters = {
        insights_url: param_insights_url,
      };
      
      let insights_url = process.env.VUE_APP_INSIGHTS_EXPORTS;
      axios.post(insights_url, parameters).then(response => {
        if (response && response.data) {
          this.guid = response.data.id;
          this.checkCompletion();
        }
      });
    },
    openNotificationsMenu(e) {
      this.showMenu = true;
      this.menuPosition.x = e.clientX - 110;
      this.menuPosition.y = e.clientY + 20;
    },
  },
  mounted() {},
  watch: {}
};
</script>

<style scoped>
.float-right {
  margin-left: 0.5rem;
}
</style>
