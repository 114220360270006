<template>
  <div>
    <v-icon  v-if="notePriv==='NONE' && displaySoup" color="rgba(0, 0, 0, 0.08)" title="You are not authorized to read or write notes">mdi-note</v-icon>
    <v-dialog v-else v-model="notesDialog" persistent max-width="600px">
        <template  v-if="displaySoup" v-slot:activator="{ on }">
          <v-icon v-on="on" v-if="showAsEmpty" color="rgba(0, 0, 0, 0.3)" title="Notes" @click="loadNote">mdi-note-outline</v-icon>
          <v-icon v-on="on" v-else color="rgba(25, 118, 210, 0.6)" title="Notes" @click="loadNote">mdi-note-text</v-icon>
        </template>
        <v-card>
          <v-progress-linear
            indeterminate
            :active="loading||saving"
          ></v-progress-linear>
          <v-form>
            <v-card-title>
              <span class="headline">{{mode}} Note on Call Id {{callId}}</span>
              <v-spacer></v-spacer>
              <v-btn x-small text icon color="primary" @click="toggleDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text v-if="loadingError!=''">
              <p>An error occurred while trying to load the note.</p>
            </v-card-text>
            <v-card-text v-else>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-if="!loading"
                      :disabled="saving||notePriv==='READ'"
                      required
                      outlined
                      auto-grow
                      rows="8"
                      v-model="note.text"
                      hint="Type a brief note"></v-textarea>
                    <p class="stamp" v-if="!loading">
                      <span v-html="getCreator"></span><br/>
                      <span v-html="getModifier"></span>
                    </p>
                  </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="notePriv=='WRITE'" x-small color="primary" :disabled="saving" @click="toggleDialog">Cancel</v-btn>
              <v-btn v-if="notePriv=='WRITE'" x-small color="primary" :disabled="saving" @click="saveNote">Save</v-btn>
              <v-btn v-if="notePriv=='READ'" x-small color="primary" @click="toggleDialog">Close</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>

  import axios from "@/utils/AxiosInstance.js";

  export default {
    props: {
      noteId: {
        required: true
      },
      noteIsBlank: {
        required: true
      },
      callId: {
        required: true
      },
      notePriv: {
        required: true,
      },
      selfSignedTranscript: {
        type: Boolean,
        required: false,
        default: false,
      },
      displaySoup: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data: () => ({
      notesIcons: ["mdi-note-outline", "mdi-note-text-outline"],
      notesDialog: false,
      note: {
        id: null,
        noteIsBlank: null,
        text: null,
        creatorName: null,
        creatorId: null,
        createdAt: null,
        modifierName: null,
        modifierId: null,
        updatedAt: null,
      },
      stamp: '',
      loading: true,
      loadingError: '',
      saving: false,
      showAsEmpty: true,
      mode: 'View',
    }),
    watch: {
      noteId: {
        handler: function () {
          this.note.id = this.noteId
          this.note.noteIsBlank = this.noteIsBlank
          this.showAsEmpty = Boolean(this.note.noteIsBlank)
          this.mode = (this.notePriv=='READ') ? 'View' : (this.note.id) ? 'Edit' : 'Create'
        }
      },
    },
    mounted() {
      this.note.id = this.noteId
      this.note.noteIsBlank = this.noteIsBlank
      this.showAsEmpty = Boolean(this.note.noteIsBlank)
      this.mode = (this.notePriv=='READ') ? 'View' : (this.note.id) ? 'Edit' : 'Create'
      this.loadNote()
    },
    computed: {
      getCreator: function() {
        if(this.note.creatorName!=null||this.note.creatorId!=null) {
          if(this.note.creatorName==null) return 'Created by: User (' + this.note.creatorId + ') no longer exists'
          else return 'Created by: ' + this.note.creatorName + ' (' + this.note.creatorId + ') @ ' + this.note.createdAt
        } else return ''
      },
      getModifier: function() {
        if(this.note.modifierName!=null||this.note.modifierId!=null) {
          if(this.note.modifierName==null) return 'Modified by: User (' + this.note.modifierId + ') no longer exists'
          else return 'Modified by: ' + this.note.modifierName + ' (' + this.note.modifierId + ') @ ' + this.note.updatedAt
        } else return ''
      },
    },
    methods: {
      toggleDialog() {
        this.notesDialog = !this.notesDialog;
        this.$emit("overlay-present", this.notesDialog)
        if (this.notesDialog) this.loadNote()
      },
      loadNote: function(e) {
        this.loading = true
        let self = this

        if(!this.note.id) {
          this.loading = false
        } else {
            let axios_params = {params: {}}
            let notes_url = process.env.VUE_APP_CALL_NOTES
            if(this.selfSignedTranscript) {
              notes_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
              axios_params['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey}
              axios_params['params']['call_id'] = this.callId
              axios_params['params']['route'] = 'notes'
            }
            axios
              .get(notes_url + this.note.id, axios_params)
              .then(response => {
                if(response.status>=400) {
                  this.loadingError = 'unable to load note'
                  this.loading = false
                } else {
                  this.note = response.data.data
                  this.note.noteIsBlank = (this.note.text.trim()=="")
                  this.loading = false
                  let pkg = { 'noteId': self.note.id, 'call_id': self.callId, 'note_is_blank': self.note.noteIsBlank }
                  self.$emit('update-call-note', pkg)
                }

              })
              .catch(error => {
                this.loadingError = 'unable to load note'
                this.loading = false
              })
        }
      },
      saveNote: function(e) {
        // ah, promises
        this.saving = true
        let self = this
        if(this.note.id) {
          // this is a put - update existing note
          let params = { 'text': (this.note.text || '') }
          let config = {headers:{}}
          let notes_url = process.env.VUE_APP_CALL_NOTES
          if(this.selfSignedTranscript) {
            notes_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
            config['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey}
            params['call_id'] = self.callId
            params['route'] = 'notes'

          }

          axios
          .put(notes_url + this.note.id, params, config)
            .then(response => {
              if (response !== undefined) {
                if (response.status >= 400) {
                  alert('Note not updated! The server returned an error: "' + response.data.error.message + ' (' + response.data.error.status + ')"');
                } else {
                  self.note.noteIsBlank = (params.text.trim()=="") ? 1:0
                  self.showAsEmpty = Boolean(self.note.noteIsBlank)
                  let pkg = { 'noteId': self.note.id, 'call_id': self.callId, 'note_is_blank': self.note.noteIsBlank }
                  this.$emit('update-call-note', pkg)
                }
              } else {
                alert('Note not updated! An un-catchable error occurred - probably a cross origin request denial');
              }
            })
            .catch(error => {
              // Error
              alert('Note not updated! The server returned an error: "' + error);
            })
            .then(function () {
              self.toggleDialog()
              self.saving = false
            })
        } else {
          // this is a post - create a new note
          let params = { 'call_id': this.callId, 'text': (this.note.text || '') }
          let config = {headers: {}}
          let notes_url = process.env.VUE_APP_CALL_NOTES
          if(this.selfSignedTranscript) {
            notes_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
            config['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey}
            params['route'] = 'notes'
          }

          axios
            .post(notes_url, params, config)
            .then(response => {
              if (response !== undefined) {
                if (response.status >= 400) {
                  alert('Note not saved! The server returned an error: "' + response.data.error.message + ' (' + response.data.error.status + ')"');
                } else {
                  let pkg = { 'noteId': response.data.id, 'call_id': params.call_id, 'note_is_blank': (params.text.trim()=="") ? 1:0 }
                  self.note.id = response.data.id
                  self.note.noteIsBlank = pkg.note_is_blank
                  self.showAsEmpty = Boolean(pkg.note_is_blank)
                  this.$emit('update-call-note', pkg)
                }
              } else {
                alert('Note not saved! An un-catchable error occurred - probably a cross origin request denial');
              }
            })
            .catch(error => {
              // Error
              alert('Note not saved! The server returned an error: "' + error);
            })
            .then(function () {
              self.toggleDialog()
              self.saving = false
            })
        }
        e.preventDefault();
      }
    },
  }

</script>

<style scoped>

  .headline {
    color: #1976d2;
  }

  .stamp {
    color: #888;
    font-size: .8rem;
    padding-left: .8rem;
  }

</style>
