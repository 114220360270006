<template>
      <v-dialog v-model="emailDialog" persistent max-width="600px">
        <template v-if="displaySoup" v-slot:activator="{ on }">
          <v-icon v-if="type=='transcript'" title="Email Transcript..." :class="getFloatClass()" v-on="on">mdi-email-arrow-right-outline</v-icon>
          <v-icon v-else title="Send Email..." :class="getFloatClass()" v-on="on">mdi-email-arrow-right-outline</v-icon>
        </template>
        <v-card>
          <v-form v-model="isValid">
            <v-card-title>
              <span class="headline">Email {{ dialog_title }}</span>
              <v-spacer></v-spacer>
              <v-btn text icon color="primary" @click="toggleDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      required
                      v-model="recipients"
                      :rules="recipientRules"
                      label="Recipient Email *"
                      hint="One or more email addresses, separated by commas"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      required
                      v-model="subject"
                      :rules="subjectRules"
                     label="Subject *"
                     hint="Give your email a subject"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div v-html="message_preamble"></div>
                    <div v-html="message_comment_note"></div>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      required
                      outlined
                      v-model="user_message"
                      :rules="messageRules"
                      label="Message *"
                      hint="Type a brief message"></v-textarea>
                  </v-col>
                </v-row>
                <small>* indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="toggleDialog" :disabled="disableCancel">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="send" :disabled="disableSend">Send</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
</template>

<script>

  import axios from "@/utils/AxiosInstance.js";
  import {mapGetters, mapActions} from "vuex";

  export default {
    props: {
      url: {
        type: String,
        required: true
      },
      printable_url: {
        type: String,
        required: false,
        default: ""
      },
      dialog_title: {
        type: String,
        required: true
      },
      feature: {
        type: String,
        required: true
      },
      reporttype: {
        type: String,
        required: true
      },
      scorecardId: {
        type: String,
        required: true
      },
      callId: {
        type: String,
        required: false,
        default: null
      },
      start_date: {
        type: String,
        required: true
      },
      end_date: {
        type: String,
        required: true
      },
      icon_position: {
        type: String,
        required: true
      },
      type: {
        type:String,
        required: true
      },
      selfSignedTranscript: {
        type: Boolean,
        required: false,
        default: false,
      },
      displaySoup: {
        type: Boolean,
        required: false,
        default: true,
      },

    },
    data: () => ({
      isValid: true,
      emailDialog: false,
      recipients: null,
      subject: null,
      message_preamble: '',
      message_comment_note: '',
      service: '',
      params: {},
      user_message: null,
      sendWasClicked: false,
      recipientRules: [
        v => !!v || 'At least one recipient is required; separate multiple recipients by commas',
        v => /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g.test(v) || 'Email address(es) must be valid'
      ],
      subjectRules: [
        v => !!v || 'A subject is required',
        v => (v && v.length >= 1) || 'A subject is required'
      ],
      messageRules: [
        v => v!='' || 'A message is required',
        v => (v && v.length >= 1) || 'A message is required'
      ],
      replaceUrl: '',
    }),
    computed: {
      ...mapGetters('users', [
      'userData',
      'userDataLoadingStatus',
      'userDataLoadingError',
      'userDataLoadingErrorStatus',
    ]),
      formatted_html_email() {
        return `<html>
            <body>
          `+this.message_preamble+`
          `+this.message_comment_note+`
          <p>
          `+this.user_message+`
          </p>
            </body>
          </html>`
      },
      base_url() {
          return process.env.VUE_APP_BASE_URL
      },
      disableSend() {
        return !this.isValid || this.sendWasClicked
      },
      disableCancel() {
        return this.sendWasClicked
      },
    },
    methods: {
      ...mapActions('users', [
      'retrieveUserData',
    ]),
      send: function(e) {

        // no more clickity-clicks
        this.sendWasClicked = true

        // ah, promises
        let self = this

        // lets send this
        let config = {headers:{}}
        if (this.type === 'transcript') {
          this.service = process.env.VUE_APP_EMAIL_SERVICE
          this.params = {
            to: this.recipients,
            from: this.userData.email,
            subject: this.subject,
            bearer: localStorage.getItem("bearer"),
            text: encodeURI(this.formatted_html_email.replace(this.url,this.replaceUrl)),
          }
          if(this.selfSignedTranscript) {
            this.service = process.env.VUE_APP_AUTHENTICATE_ROUTE
            this.params = {
              to: this.recipients,
              from: this.userData.email,
              subject: this.subject,
              bearer: localStorage.getItem("bearer"),
              text: encodeURI(this.formatted_html_email),
              call_id: this.$route.params.id,
              route: 'emails'
            }
            config['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey}
          }

        } else {
            this.service  = process.env.VUE_APP_EMAIL_PDF
            this.params = {
              pdf_url: this.printable_url == "" ? this.base_url + this.url : this.base_url + this.printable_url,
              pdf_name: this.dialog_title,
              delivery_address: this.recipients,
              subject: this.subject,
              bearer: localStorage.getItem("bearer"),
              text: (this.replaceUrl == '') ? encodeURI(this.formatted_html_email) : encodeURI(this.formatted_html_email.replace(this.base_url + this.url,this.replaceUrl)),
            }
        }

        axios
          .post(this.service , this.params, config)
          .then(response => {
            if(response !== undefined) {
              if (response.status >= 400) {
                alert('Email not sent! The server returned an error: "' + response.data.error.message + ' (' + response.data.error.status + ')"');
              }
              // re-enable buttons
              self.sendWasClicked = false
            } else {
              //alert('Email not sent! An un-catchable error occurred - probably a cross origin request denial');
              // re-enable buttons
              self.sendWasClicked = false
            }
          })
          .catch(error => {
            // Error
            //alert('Email not sent! The server returned an error: "' + error );
            // re-enable buttons
            self.sendWasClicked = false
          })
          .then(function() {
            self.toggleDialog()
          })

        e.preventDefault();
      },
      getFloatClass() {
        return this.icon_position
      },
      toggleDialog() {
        this.emailDialog = !this.emailDialog
        this.$emit("overlay-present", this.emailDialog)
      },
      openDialogWithUrl(url) {
        this.replaceUrl = url
        this.toggleDialog()
      },
      get_message_preamble() {

        if (this.type === 'call') {
            this.message_preamble = `
          <p>This ` + this.reporttype.replace(/-/g,' ') + ` was sent by ` + this.userData.firstName + ` ` + this.userData.lastName + ` (` + this.userData.email + `). Please contact ` + this.userData.firstName + ` if you have any questions.
          You must have an active CallFinder user profile with authorized access to this call to navigate to this scorecard.</p>
          <a href="` + this.base_url + this.url + `">Click here to access the call scorecard</a></p>`
        }

        if (this.type === 'agent') {
            this.message_preamble = `
          <p>This ` + this.reporttype.replace(/-/g,' ') + ` was sent by ` + this.userData.firstName + ` ` + this.userData.lastName + ` (` + this.userData.email + `). Please contact ` + this.userData.firstName + ` if you have any questions.</p>
          <p>This ` + this.reporttype.replace(/-/g,' ') + (this.callId!==null ? ' (and associated call recording) ' : ' ') + `may also be accessed by clicking the following link (scorecard access requires a valid CallFinder user account):<br/>
          <a href="` + this.base_url + this.url + `">` + this.base_url + this.url + `</a></p>`
        }

        if (this.type === 'team') {
          this.message_preamble = `
           <p>This ` + this.reporttype.replace(/-/g,' ') + ` was sent by ` + this.userData.firstName + ` ` + this.userData.lastName + ` (` + this.userData.email + `). Included with the ` + this.reporttype.replace(/-/g,' ') + ` are the Top 5 Scoring Agents, Bottom 5 Scoring Agents, and Agent Statistics.
           Please contact ` + this.userData.firstName + ` if you have any questions.</p>
         `
        }
        if (this.type === 'transcript') {
            this.message_preamble = `
          <p>This transcript link was sent by ` + this.userData.firstName + ` ` + this.userData.lastName + ` (` + this.userData.email + `). Please contact ` + this.userData.firstName + ` if you have any questions.
          </p>
          <p><a href="` + this.base_url + this.url + `"target="_BLANK">Click here to access the transcript link</a></p>`
        }

      },
      get_message_comment_note() {
        this.message_comment_note = `
          <p>Comments from ` + this.userData.firstName + ` can be found below:</p>
        `
      },
    },
    mounted() {
      if (this.userDataLoadingStatus=='loaded') {
        this.get_message_preamble()
        this.get_message_comment_note()
      } else {
        this.retrieveUserData()
      }
    },
    watch: {
      userDataLoadingStatus: function(status) {
        if(status=='loaded') {
          this.get_message_preamble()
          this.get_message_comment_note()
        }
      }
    }
  }

</script>

<style scoped>

  .headline {
    color: #1976d2;
  }

  .float-right {
    margin-left: .5rem;
  }

  .float-left {
    margin-right: .5rem;
  }


</style>
