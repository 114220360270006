<template>
  <v-content style="height: 100vh; max-height: calc(100%);">
    <v-container fluid pa-0 style="height: 100vh; max-height: calc(100%);">
      <v-row>
        <v-col
          style="height: 100vh; max-height: calc(100%); padding: 0; position: relative; align: center;"
        >
          <Filters
            feature="scorecards-v2"
            @adjustments-filter-updated-reload="filterUpdated"
          ></Filters>

          <v-row>
            <v-col class="pl-12 pb-0">
            <template v-if="loadHeader">
              <scorecard-breadcrumbs :linkEnabled="true" :scorecardHeader="scorecardTitleStr" :dynaLabelHeader="scorecardDynalabelStr" :agentHeader="agentName" :level="'call'" :singleDynaCount="singleDynaCount" @go-to-page="goBack"></scorecard-breadcrumbs>
            </template>
          </v-col>
          <v-col class="text-right pr-10">
            <v-btn fab small dark color="primary" href="/faq/0" target="_blank" title="Click for Scorecard Help and FAQs">
              <v-icon dark>mdi-help-circle-outline</v-icon>
            </v-btn>
          </v-col>
          </v-row>

          <v-row>
            <v-col>
              <scorecard-processing-status :type="'call'"></scorecard-processing-status>
            </v-col>
          </v-row>

          <div class="main-container">

            <div class="stats-container">
              <div class="expand-button">
                <v-btn icon dark color="blue-grey">
                  <v-icon small @click="toggleExpansion">{{expansionIcon}}</v-icon>
                </v-btn>
              </div>
              <scorecards-side-panel v-show="showStats" class="pt-0 mt-0 px-8"></scorecards-side-panel>
              <p v-show="!showStats" class="blue-grey--text collapsed-text">Statistics</p>
            </div>

            <div class="tabs-container">
              <div>
                <v-tabs v-model="active_tab" grow @change="tabChanged">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab
                    key="scorecard"
                    href="#scorecard"
                    class="ml-2 mr-2"
                    >Scorecard<v-spacer></v-spacer>
                    <v-btn :color="pinColor(scorecard_tab)" icon x-small
                      ><v-icon @click="pinFavorite(scorecard_tab)"
                        >mdi-pin</v-icon
                      ></v-btn
                    ></v-tab
                  >
                  <v-tab
                    key="call-score-data-grid"
                    href="#call-score-data-grid"
                    class="ml-2 mr-2"
                  >
                    Calls<v-spacer></v-spacer>
                    <v-btn :color="pinColor(call_tab)" icon x-small
                      ><v-icon
                        :color="pinColor(call_tab)"
                        @click="pinFavorite(call_tab)"
                        >mdi-pin</v-icon
                      ></v-btn
                    >
                  </v-tab>

                  <v-tab
                    key="call-score-distribution"
                    href="#call-score-distribution"
                    class="ml-2 mr-2"
                    >Distribution<v-spacer></v-spacer>
                    <v-btn :color="pinColor(distribution_tab)" icon x-small
                      ><v-icon @click="pinFavorite(distribution_tab)"
                        >mdi-pin</v-icon
                      ></v-btn
                    >
                  </v-tab>

                  <v-tab
                    key="call-trends"
                    href="#call-trends"
                    class="ml-2 mr-2"
                  >Trends<v-spacer></v-spacer>
                    <v-btn :color="pinColor(trends_tab)" icon x-small>
                      <v-icon @click="pinFavorite(trends_tab)">mdi-pin</v-icon>
                    </v-btn>
                  </v-tab>

                  <v-tab
                    v-for="row in callScorecardList"
                    :key="'call-scorecard-' + row.callId"
                    :href="'#call-scorecard-' + row.callId"
                    class="ml-2 mr-2"
                  >
                    <v-icon small left color="red" @click="closeTab(row.callId)"
                      >mdi-close</v-icon
                    >
                    Call Scorecard ({{ row.callId }}) <v-spacer></v-spacer>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="active_tab">
                  <v-tab-item :value="'scorecard'">
                    <scorecard-loading-status
                      v-if="
                        scorecardV2DataLoadingStatus != 'loaded' ||
                          scorecardV2DataLoadingErrorStatus ||
                          !scorecardAvailable
                      "
                      :message="scorecardMessageType"
                      :type="scorecardLoadingType"
                    >
                    </scorecard-loading-status>
                    <scorecard-view
                      v-else
                      :agentId="Number($route.params.agentId)"
                      :format="'scorecard'"
                    ></scorecard-view>
                  </v-tab-item>
                  <v-tab-item :value="'call-score-data-grid'">
                    <v-row class="pb-4 pt-4">
                      <v-col cols="12">
                        <v-card-title>
                          <v-text-field
                            class="mt-0 mb-0 mr-2 ml-2"
                            :clearable="!searchDisabled"
                            dense
                            hide-details
                            v-model="search"
                            :disabled="!searchOrSort"
                            @keydown.enter.prevent="searchCalls"
                            @click:clear="clearSearch"
                            label="Find Calls"
                            variant="solo"
                          >
                          </v-text-field>
                          <v-btn
                            :disabled="searchDisabled"
                            :dark="!searchDisabled"
                            small
                            color="primary"
                            @click="searchCalls"
                          >
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                          <div class="pt-4 pl-6">
                            <export-menu
                              @exportClick="exportClick"
                            ></export-menu>
                            <scorecard-download
                              ref="ScorecardDownload"
                              v-if="
                                allCallScorecardDataV2LoadingStatus == 'loaded'
                              "
                              class="float-right"
                              :scorecardId="Number(uriScorecardId)"
                              :scorecardUrl="callScorecardDataUrl"
                              :scorecardTitle="scorecardHeader"
                              :dateRange="getDateRangeString()"
                            ></scorecard-download>
                          </div>
                        </v-card-title>
                      </v-col>
                    </v-row>
                    <v-data-table
                      v-if="userDataLoadingStatus == 'loaded'"
                      class="overflow-y-auto row-height-consistent"
                      dense
                      :loading="paginationDisabled"
                      loading-text="Scorecard calls loading, please wait..."
                      :headers="filteredCallsHeaders"
                      :items="getScorecardCalls"
                      :items-per-page="pageSize"
                      :server-items-length="itemRowCount"
                      :fixed-header="true"
                      :height="fixHeight"
                      :page="pageNum"
                      @update:options="loadItems"
                      @input="enterSelect($event)"
                      :footer-props="{
                        itemsPerPageOptions: [10, 25, 50, 100],
                        disableItemsPerPage: paginationDisabled,
                        disablePagination: paginationDisabled
                      }"
                    >

                    <template v-for="(header, i) in filteredCallsHeaders" v-slot:[`header.${header.value}`]="{ header }">
                      <v-tooltip top :key="'header-'+i"
                      >
                        <template v-slot:activator="{ on }">
                          <span v-if="header.text != getFormattedTitleName(header.text)" v-on="on">{{getFormattedTitleName(header.text)}}&hellip;</span>
                          <span v-else v-on="on">{{header.text}}</span>
                        </template>
                        <span>{{header.text}}</span>
                      </v-tooltip>
                    </template>

                      <template v-slot:no-data>
                        <v-alert
                          :value="true"
                          color="warning"
                          icon="mdi-message-alert-outline"
                          class="ma-2"
                          dark
                        >
                          No calls found for your selected filters. Please try
                          another selection.
                        </v-alert>
                      </template>
                      <template v-slot:item.caller_number="{ item }">{{
                        formatPhoneNumber(item.caller_number)
                      }}</template>

                      <template v-slot:item.duration="{ item }">{{
                        item.duration
                      }}</template>

                      <template v-slot:item.call_score="{ item }">
                        <score-chip
                          v-if="!item.excludedCall"
                          :score="item.call_score"
                          :modified_by="item.modified_by"
                          :modified_on="item.modified_on"
                          :aggregate_cat="false"
                        ></score-chip>
                        <v-icon v-else color="error"
                          >mdi-close-circle-outline</v-icon
                        >
                      </template>

                      <template v-slot:item.note_id="{ item }">
                        <v-layout justify-center
                          ><note-edit-control
                            v-if="userDataLoadingStatus == 'loaded'"
                            :notePriv="userData.privileges.notes"
                            :callId="Number(item.id)"
                            :noteId="item.note_id == null ? 0 : item.note_id"
                            :noteIsBlank="item.note_is_blank"
                          ></note-edit-control
                        ></v-layout>
                      </template>

                      <template v-slot:item.has_tags="{ item }">
                        <tags-edit-control
                          v-if="userDataLoadingStatus == 'loaded'"
                          :tagPriv="userData.privileges.tags"
                          :callId="Number(item.id)"
                          :hasTags="Boolean(item.has_tags)"
                        ></tags-edit-control>
                      </template>

                      <template v-slot:item.id="{ item }">
                        <v-btn
                          text
                          small
                          color="primary"
                          @click="
                            createCallScorecardTab(
                              item.id,
                              'call-score-data-grid'
                            )
                          "
                          >{{ item.id }}</v-btn
                        >
                      </template>

                      <template v-slot:item.custom_metadata="{ item }">
                        <custom-metadata-modal
                          class="center-control"
                          :callId="item.id"
                        ></custom-metadata-modal>
                      </template>
                      <template
                        v-for="(slot, i) in callColumnSlots"
                        v-slot:[`item.${slot.id}`]="{ item }"
                      >
                        <score-chip
                          v-if="!item.excludedCall"
                          :score="item[slot.id]"
                          :key="'sc-'+i"
                          :modified_by="item[slot.id + '_modified_by']"
                          :modified_on="item[slot.id + '_modified_on']"
                          :applicable="item[slot.id + '_applicable']"
                          :manual_applicable="
                            item[slot.id + '_manual_applicable']
                          "
                          :category_chip="true"
                          :nonAppCatHandling="
                            allCallScorecardDataV2.nonAppCatHandling
                          "
                          :aggregate_cat="false"
                        ></score-chip>
                      </template>
                      <template v-slot:item.audit="{ item }">

                        <v-layout justify-center
                          ><audit-icon v-if="userDataLoadingStatus == 'loaded'"
                            :callId="Number(item.id)"
                            :auditedBefore="Boolean(item.has_been_audited)"
                            :isScorecards="true"
                          ></audit-icon
                        ></v-layout>
                      </template>

                      <template v-slot:footer></template>
                    </v-data-table>
                  </v-tab-item>
                  <v-tab-item :value="'call-score-distribution'">
                    <scorecard-loading-status
                      v-if="scorecardDistributionDataLoadingStatus != 'loaded'"
                      :message="distributionLoadingMessage"
                      :type="distributionLoadingType"
                    >
                    </scorecard-loading-status>
                    <v-card v-else flat>
                      <v-card-text>
                        <call-score-distribution
                          :distributiondata="getDistributionData"
                          :createCallScorecardTabInParent="
                            createCallScorecardTab
                          "
                        ></call-score-distribution>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item :value="'call-trends'">
                    <v-card flat>
                      <v-card-text>
                        <scorecard-trends
                          ref="trendTab"
                          :dynalabelId="this.$route.params.dynalabelId"
                          :agentId="this.$route.params.agentId"
                          :scorecardId="this.$route.params.scorecardId"
                        >
                        </scorecard-trends>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item
                    v-for="row in callScorecardList"
                    :key="row.callId"
                    :value="'call-scorecard-' + row.callId"
                  >
                    <call-scorecard
                      v-if="userDataListLoadingStatus == 'loaded'"
                      :callId="String(row.callId)"
                      :dynalabelId="String(row.dynalabelId)"
                      :agentId="String(row.agentId)"
                      :scorecardId="String(row.scorecardId)"
                      :ref="'callScorecard-' + String(row.callId)"
                      :location="active_tab"
                      :pageSize="pageSize"
                      :pageNum="pageNum"
                      :callSearch="callSearch"
                      :singleDynaLabel="singleDynaCount"
                    ></call-scorecard>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <ScheduledExportsModal
        :showScheduledExportsModal="showScheduledExportsModal"
        @HIDE_SCHEDULED_EXPORTS_MODAL="hideScheduledExportsModal"
        :isNew="true"
        :insightOrScorecard="'scorecards'"
        :pdfDelivery="false"
      ></ScheduledExportsModal>
    </v-container>
  </v-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import callScoreDistribution from "@/components/CallScoreDistribution.vue";
import CallScorecard from "@/components/Scorecards/CallScorecard.vue";
import noteEditControl from "@/components/Controls/NoteEditControl.vue";
import tagsEditControl from "@/components/Controls/TagsEditControl.vue";
import moment from "moment";
import CustomMetadataModal from "@/components/Widgets/CustomMetadataModal.vue";
import Filters from "@/components/Filters.vue";
import ScoreChip from "@/components/Scorecards/ScoreChip.vue";
import AuditIcon from "@/components/Widgets/AuditIcon.vue";
import ScorecardView from "@/components/Scorecards/ScorecardView.vue";
import ExportMenu from "@/components/Widgets/ExportMenu.vue";
import ScorecardDownload from "@/components/Controls/ScorecardDownload.vue";
import ScorecardLoadingStatus from "../../components/Scorecards/ScorecardLoadingStatus.vue";
import ScorecardsSidePanel from "../../components/Scorecards/ScorecardsSidePanel.vue";
import CacheHandler from "@/utils/CacheHandler";
import ScheduledExportsModal from "@/components/Widgets/ScheduledExportsModal.vue";
import ScorecardProcessingStatus from "../../components/Scorecards/ScorecardProcessingStatus.vue";
import ScorecardTrends from "@/components/Scorecards/ScorecardTrends.vue";
import ScorecardBreadcrumbs from '../../components/Breadcrumbs/ScorecardBreadcrumbs.vue';
import selectPage from "@/mixins/selectPage";

export default {
  mixins: [selectPage],
  components: {
    ScorecardTrends,
    callScoreDistribution,
    CallScorecard,
    noteEditControl,
    tagsEditControl,
    CustomMetadataModal,
    Filters,
    ScoreChip,
    AuditIcon,
    ScorecardView,
    ExportMenu,
    ScorecardDownload,
    ScorecardLoadingStatus,
    ScorecardsSidePanel,
    ScheduledExportsModal,
    ScorecardProcessingStatus,
    ScorecardBreadcrumbs
  },
  data() {
   return {
      search: "",
      active_tab: "call-score-data-grid",
      active_tab_on_empty: "scorecard",
      callScorecardList: [],
      pageNum: 1,
      pageSize: 10,
      scorecard_tab: 1,
      call_tab: 2,
      distribution_tab: 3,
      trends_tab: 4,
      lastFav: 0,
      orderDirection: "desc",
      orderBy: null,
      categorySortId: null,
      showScheduledExportsModal: false,
      callSearch: "",
      showStats: true,
    };
  },
  computed: {
    expansionIcon() {
      return (this.showStats) ? "mdi-arrow-collapse-left" : "mdi-arrow-expand-right"
    },
    ...mapGetters("scorecardsV2Calls", [
      "callScorecardDataV2",
      "allCallScorecardDataV2",
      "allCallScorecardDataV2LoadingStatus",
      "allCallScorecardDataV2LoadingErrorStatus",

      "scorecardV2Data",
      "scorecardV2DataLoadingStatus",
      "scorecardV2DataLoadingError",
      "scorecardV2DataLoadingErrorStatus",

      "scorecardDistributionData",
      "scorecardDistributionDataLoadingStatus",
      "scorecardDistributionDataLoadingError",
      "scorecardDistributionDataLoadingErrorStatus",

      "scorecardTrendsData",
      "scorecardTrendsDataLoadingStatus",
      "scorecardTrendsDataLoadingError",
      "scorecardTrendsDataLoadingErrorStatus",

      "scorecardCallLogSkeleton",
      "scorecardCallLogSkeletonLoadingStatus",
      "scorecardCallLogSkeletonLoadingError",
      "scorecardCallLogSkeletonLoadingErrorStatus",

      "favoriteScorecardTab",
      "callScorecardDataUrl"
    ]),

    ...mapGetters("presentation", [
      "presentationScorecardsData",
      "presentationScorecardsDataLoadingStatus",
      "presentationScorecardsDataLoadingError",
      "presentationScorecardsDataLoadingErrorStatus"
    ]),
    ...mapGetters("users", [
      "userDataList",
      "userData",
      "userDataLoadingStatus",
      "cfOnlyUser",
      "administratorUser",
      "userDataListLoadingStatus"
    ]),
    ...mapGetters("dateRange", ["start_date", "end_date"]),
    fixHeight() {
      return (window.innerHeight>=1080 || this.itemRowCount<=10) ? '100%' : "55vh"
    },
    paginationDisabled() {
      return (
        this.allCallScorecardDataV2LoadingStatus != "loaded" ||
        this.scorecardCallLogSkeletonLoadingStatus != "loaded" ||
        this.presentationScorecardsDataLoadingStatus != 'loaded'
      );
    },
    searchOrSort() {
      return this.allCallScorecardDataV2LoadingStatus == "loaded";
    },
    searchDisabled() {
      let validSearch = this.search && this.search.trim() != "" ? true : false;
      return !this.searchOrSort || !validSearch;
    },
    callsAvailable() {
      return this.allCallScorecardDataV2.hasOwnProperty("calls")
        ? this.allCallScorecardDataV2.calls.length > 0
        : false;
    },
    itemRowCount() {
      return this.scorecardCallLogSkeleton.hasOwnProperty("call_count")
        ? this.scorecardCallLogSkeleton.call_count
        : 0;
    },
    scorecardAvailable() {
      //we need to access 'items' like this since presentation-service looks at an array at the aggregate level
      return this.scorecardV2Data.hasOwnProperty("items") &&
        this.scorecardV2Data.items.length > 0
        ? this.scorecardV2Data.items[0].metrics.hasOwnProperty("totalCalls")
        : false;
    },
    getUnit() {
      return moment(this.end_date).diff(moment(this.start_date), "days") > 1
        ? "day"
        : "hour";
    },
    distributionLoadingType() {
      if (this.scorecardDistributionDataLoadingError) {
        return "error";
      } else if (
        this.allCallScorecardDataV2LoadingStatus == "loaded" &&
        !this.callsAvailable
      ) {
        return "nodata";
      } else return "loading";
    },
    distributionLoadingMessage() {
      if (this.scorecardDistributionDataLoadingErrorStatus)
        return "There was an error loading call distributions";
      else if (
        this.allCallScorecardDataV2LoadingStatus == "loaded" &&
        !this.callsAvailable
      ) {
        return "No calls found for your selected filters. Please try another selection.";
      } else return "Call distributions loading, please wait...";
    },
    callsLoadingType() {
      if (
        this.allCallScorecardDataV2LoadingErrorStatus ||
        this.scorecardCallLogSkeletonErrorStatus
      ) {
        return "error";
      } else if (
        this.allCallScorecardDataV2LoadingStatus == "loaded" &&
        !this.callsAvailable
      ) {
        return "nodata";
      } else return "loading";
    },
    callsMessageType() {
      if (
        this.allCallScorecardDataV2LoadingErrorStatus ||
        this.scorecardCallLogSkeletonErrorStatus
      )
        return "There was an error loading calls for this scorecard...";
      else if (
        this.allCallScorecardDataV2LoadingStatus == "loaded" &&
        !this.callsAvailable
      ) {
        return "No calls found for your selected filters. Please try another selection.";
      } else return "Scorecard calls loading, please wait...";
    },
    scorecardLoadingType() {
      if (this.scorecardV2DataLoadingErrorStatus) return "error";
      else if (
        this.scorecardV2DataLoadingStatus == "loaded" &&
        !this.scorecardAvailable
      ) {
        return "nodata";
      } else return "loading";
    },
    scorecardMessageType() {
      if (this.scorecardV2DataLoadingErrorStatus) {
        return "There was an error loading this scorecard...";
      } else if (
        this.scorecardV2DataLoadingStatus == "loaded" &&
        !this.scorecardAvailable
      ) {
        return "No calls found for your selected filters. Please try another selection.";
      } else return "Scorecard loading, please wait...";
    },
    agentName() {
      if (this.$route.params.agentId == 0) {
        return "All Agents";
      } else {
        return this.scorecardV2Data.agentName;
      }
    },
    scorecardHeader() {
      return (
        this.scorecardV2Data.scorecardTitle +
        "\n" +
        this.scorecardV2Data.dynalabelTitle +
        "\n" +
        this.agentName
      );
    },
    scorecardTitleStr() {
      return (
        this.scorecardV2Data.scorecardTitle);
    },
    dynalabelCount() {
      return this.scorecardV2Data.dynalabelCount
    },
    singleDynaCount() {
      return this.dynalabelCount < 2
    },
    scorecardDynalabelStr() {
      return (
        this.scorecardV2Data.dynalabelTitle);
    },
    uriScorecardId() {
      return this.$route.params.scorecardId;
    },
    loadHeader() {
      return this.scorecardV2DataLoadingStatus == "loaded";
    },
    getScorecardCalls() {
      let callInList = {};
      if (this.allCallScorecardDataV2LoadingStatus == "loaded" &&  this.allCallScorecardDataV2.hasOwnProperty("calls")) {
        if (this.allCallScorecardDataV2.calls.length > 0) {
          for (let i = 0; i < this.allCallScorecardDataV2.calls.length; i++) {
            if (this.allCallScorecardDataV2.calls[i]) {
              let excludedCall = this.allCallScorecardDataV2.calls[i].excludedCall;
              this.allCallScorecardDataV2.calls[i].excludedCall = excludedCall;
              this.allCallScorecardDataV2.calls[i].adjusted_datetime = this.allCallScorecardDataV2.calls[i].adjustedDateTime;
              this.allCallScorecardDataV2.calls[i].caller_number = this.allCallScorecardDataV2.calls[i].callerNumber;
              this.allCallScorecardDataV2.calls[i].talk_time = this.formatHMS(this.allCallScorecardDataV2.calls[i].duration);
              if (this.allCallScorecardDataV2.calls[i].displayHandleTime == 1) {
                this.allCallScorecardDataV2.calls[i].handle_time = this.formatHMS(this.allCallScorecardDataV2.calls[i].handleTime);
              }
              this.allCallScorecardDataV2.calls[i].call_score = this.allCallScorecardDataV2.calls[i].callScore;
              this.allCallScorecardDataV2.calls[i].callId = this.allCallScorecardDataV2.calls[i].id;
              this.allCallScorecardDataV2.calls[i].agent_name = this.allCallScorecardDataV2.calls[i].agentName;
              callInList = this.allCallScorecardDataV2.calls[i];
              if (typeof callInList != "undefined" && callInList.hasOwnProperty("categories")) {
                callInList.categories.forEach(category => {
                  callInList[category.id] = category.score;
                  callInList[category.id + "_modified_by"] = category.modified_by;
                  callInList[category.id + "_modified_on"] = category.modified_on;
                  callInList[category.id + "_applicable"] = String(category.applicable);
                  callInList[category.id + "_manual_applicable"] = String(category.manual_applicable);
                });
              }
            }
          }
        }
        return this.allCallScorecardDataV2.calls;
      } else {
        return [];
      }
    },
    callColumnSlots() {
      let slots = [];
      if (
        this.allCallScorecardDataV2.hasOwnProperty("calls") &&
        this.allCallScorecardDataV2.calls.length > 0
      ) {
        for (let i = 0; i < this.allCallScorecardDataV2.calls.length; i++) {
          if (this.allCallScorecardDataV2.calls[i]) {
            this.allCallScorecardDataV2.calls[i].categories.forEach(
              category => {
                slots.push({
                  id: category.id,
                  modified_on: category.modified_on,
                  modified_by: String(category.modified_by)
                });
              }
            );
          }
        }
      }
      return slots;
    },
    getDistributionData() {
      return this.scorecardDistributionDataLoadingStatus == "loaded"
        ? this.scorecardDistributionData
        : [];
    },
    durationValHeaderValue() {
      if (this.allCallScorecardDataV2.hasOwnProperty("timeDisplay")) {
        return this.allCallScorecardDataV2.timeDisplay == "1"
          ? "handle_time"
          : "talk_time";
      } else {
        return "talk_time";
      }
    },
    durationValHeaderText() {
      if (this.allCallScorecardDataV2.hasOwnProperty("timeDisplay")) {
        return this.allCallScorecardDataV2.timeDisplay == "1"
          ? "Handle Time"
          : "Talk Time";
      } else {
        return "Talk Time";
      }
    },
    callsHeaders() {
      var thisThis = this;
      let headers = [
        {
          text: "Date/Time",
          value: "adjusted_datetime",
          key: "calls[].adjustedDateTime",
          sortable: this.searchOrSort
        },
        {
          text: "Customer",
          value: "caller_number",
          key: "calls[].ani||calls[].pots",
          sortable: this.searchOrSort
        },
        {
          text: this.durationValHeaderText,
          value: this.durationValHeaderValue,
          key: "calls[].duration",
          align: "center",
          sortable: this.searchOrSort
        },
        {
          text: "Score",
          value: "call_score",
          key: "calls[].callScore",
          align: "center",
          sortable: this.searchOrSort
        },
        {
          text: "Agent",
          value: "agent_name",
          class: "headerAgentName",
          key: "calls[].agentName",
          sortable: this.searchOrSort
        },
        {
          text: "Notes",
          value: "note_id",
          key: "calls[].note_id",
          align: "center",
          sortable: this.searchOrSort
        },
        {
          text: "Tags",
          value: "has_tags",
          key: "calls[].has_tags",
          align: "center",
          sortable: this.searchOrSort
        },
        {
          text: "Call ID",
          value: "id",
          key: "calls[].id",
          align: "center",
          sortable: this.searchOrSort
        },
        {
          text: "Custom Metadata",
          value: "custom_metadata",
          key: "customMetadata",
          align: "center",
          sortable: false
        }
      ];
      if (this.scorecardCallLogSkeleton.hasOwnProperty("categories")) {
        this.scorecardCallLogSkeleton.categories.forEach(category => {
          let nameFixed = thisThis.decodeHtml(category.category_title);
          headers.push({
            text: nameFixed,
            key: "calls[].categories[]",
            value: category.id,
            align: "center",
            sortable: this.searchOrSort,
            class: "asc-fixed-col"
          });
        });
      }
      headers.push({
        text: "Client Call Identifier",
        value: "in_asterisk_id",
        key: "calls[].in_asterisk_id",
        sortable: this.searchOrSort
      });
      // currently proxying and can manage searches, add audit on the right
      if(this.userData.id != this.userData.csrep_userid && this.userData.privileges.callFinderManageSearches) {
        headers.push({
          text: "Audit Call",
          value: "audit",
          sortable: false,
          key: "calls[].in_asterisk_id",
        })
      }
      return headers;
    },
    filteredCallsHeaders() {
      // check against presentation service source keys
      var sourceKeys = this.presentationScorecardsData.map(p => p.source_key);
      return this.callsHeaders
        .filter(o => {
          return sourceKeys.includes(o.key);
        })
        .sort(
          ({ key: r }, { key: t }) =>
            sourceKeys.indexOf(r) - sourceKeys.indexOf(t)
        );
    },
  },
  methods: {
    ...mapActions({
      retrieveAllCallScorecardDataV2:
        "scorecardsV2Calls/retrieveAllCallScorecardDataV2",
      retrieveAuthorizedUserListData: "users/retrieveAuthorizedUserListData",
      retrieveCallCategories: "scorecardsV2Calls/retrieveCallCategories",
      retrievePresentationScorecardsData:
        "presentation/retrievePresentationScorecardsData",
      retrieveScorecard: "scorecardsV2Calls/retrieveScorecard",
      setFavoriteScorecardTab: "scorecardsV2Calls/setFavoriteScorecardTab",
      retrieveScorecardDistributions:
        "scorecardsV2Calls/retrieveScorecardDistributions",
      retrieveCallLogSkeleton: "scorecardsV2Calls/retrieveCallLogSkeleton",
    }),
    getFormattedTitleName(name) {
      // if the name is greater than 24 in length, add the words together until the name is greater than 20
      if(name.length <= 20) {
        return name
      }
      let nameArray = name.split(' ')
      let rtn_name = ""
      nameArray.forEach(element => {
        if(rtn_name.length < 20) {
          rtn_name += element
        }
        if(rtn_name.length < 19) {
          rtn_name += " "
        }
      })
      return rtn_name
    },
    exportClick(item) {
      switch (item) {
        case "downloadExport":
          // do immediate export stuff
          this.$refs.ScorecardDownload.exportScorecard();
          break;
        case "scheduleExport":
          // pop schedule export modal to do schedule stuff
          this.showScheduledExportsModal = true;
          break;
      }
    },
    filterUpdated() {
      this.resetData();
      this.retrieveData();
      this.closeTabs();
    },
    pinFavorite(favoriteTab) {
      if (favoriteTab == this.favoriteScorecardTab) {
        this.setFavoriteScorecardTab(0);
      } else {
        this.setFavoriteScorecardTab(favoriteTab);
        this.lastFav = favoriteTab;
      }
    },
    pinColor(favoriteTab) {
      return favoriteTab != this.favoriteScorecardTab ? "accent" : "warning";
    },
    loadPresentationData() {
      this.retrievePresentationScorecardsData({
        scorecard_id: this.$route.params.scorecardId,
        aggregate_type: "call",
        display_type: "D"
      });
    },
    loadItems({ sortDesc, page, itemsPerPage, sortBy }) {
      this.pageNum = page;
      this.pageSize = itemsPerPage;
      if (sortBy.length > 0) {
        //paginate/sort
        let sortItem = sortBy[0];
        if (typeof sortItem == "number") {
          //we know we are sorting on a category id - no other index value is a number...
          this.orderBy = "cat_score";
          this.categorySortId = sortItem;
        } else {
          //not sorting on category id
          this.orderBy = sortItem;
          this.categorySortId = null;
        }
        this.orderDirection =
          sortDesc.length > 0 && sortDesc[0] ? "desc" : "asc";
      } else {
        this.orderBy = null;
        this.categorySortId = null;
        this.orderDirection = "desc";
      }
      this.retrieveAllCallScorecardDataV2(this.getParams());
    },
    decodeHtml(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    goBack(location) {
      this.selectScorecardPage(location, false);
    },
    tabChanged(tabName) {
      this.killAudio()
      if (tabName == undefined) {
        switch (this.favoriteScorecardTab) {
          case 4:
            location.hash = "#call-trends";
            break;
          case 3:
            location.hash = "#call-score-distribution";
            break;
          case 2:
            location.hash = "#call-score-data-grid";
            break;
          case 1:
            location.hash = "#scorecard";
            break;
          default:
            switch (this.lastFav) {
              case 4:
                location.hash = "#call-trends";
                break;
              case 3:
                location.hash = "#call-score-distribution";
                break;
              case 2:
                location.hash = "#call-score-data-grid";
                break;
              case 1:
              default:
                location.hash = "#scorecard";
                break;
            }
            this.active_tab_on_empty = "scorecard";
            break;
        }
      } else {
        location.hash = '#' + tabName
      }
    },
    searchCalls() {
      this.pageNum = 1
      this.callSearch = this.search
      let params = this.getParams();
      this.retrieveAllCallScorecardDataV2(params);
      this.retrieveCallLogSkeleton(params);
    },
    clearSearch() {
      this.search = "";
      this.callSearch = this.search
      let params = this.getParams();
      this.retrieveAllCallScorecardDataV2(params);
      this.retrieveCallLogSkeleton(params);
    },
    resetPage() {
      this.callScorecardList = [];
    },
    createCallScorecardTab(id, source) {
      let tab = "call-scorecard-" + id;
      if (
        this.callScorecardList.findIndex(c => Number(c.callId) === Number(id)) <
        0
      ) {
        this.callScorecardList.unshift({
          scorecardId: this.$route.params.scorecardId,
          dynalabelId: this.$route.params.dynalabelId,
          agentId: this.$route.params.agentId,
          callId: id
        });
        if (this.callScorecardList.length > 5) this.callScorecardList.pop();
      }

      this.active_tab_on_empty = source;
      this.updateLocation(tab);
      this.active_tab = tab;
    },
    closeTab(id) {
      this.killAudio()
      let tab = "";
      this.callScorecardList = this.callScorecardList.filter(call => {
        return id != call.callId;
      });
      if (this.callScorecardList.length == 0) {
        tab = this.active_tab_on_empty;
      } else {
        tab = "call-scorecard-" + this.callScorecardList[0].callId;
      }
      this.updateLocation(tab);
      this.active_tab = tab;
    },
    closeTabs() {
      this.killAudio()
      this.callScorecardList = [];

      let tab = this.active_tab_on_empty;
      this.updateLocation(tab);
      this.active_tab = tab;
    },
    getLocation() {
      let lh = location.hash;
      if (lh) {
        let m = lh.match(/(#call-scorecard-)(\d+)/);
        if (m !== null) {
          this.createCallScorecardTab(m[2], "call-score-data-grid");
        } else {
          let p = lh.match(/#([A-z-]+)/);
          if (p !== null) {
            this.active_tab = p[1];
            setTimeout(this.updateLocation(p[0]), 1000);
          }
        }
      } else {
        switch (this.favoriteScorecardTab) {
          case 4:
            this.active_tab = "call-trends";
            this.active_tab_on_empty = "call-trends";
            location.hash = "#call-trends";
            break;
          case 3:
            this.active_tab = "call-score-distribution";
            this.active_tab_on_empty = "call-score-distribution";
            location.hash = "#call-score-distribution";
            break;
          case 2:
            this.active_tab = "call-score-data-grid";
            this.active_tab_on_empty = "call-score-data-grid";
            location.hash = "#call-score-data-grid";
            break;
          case 1:
          default:
            this.active_tab = "scorecard";
            this.active_tab_on_empty = "scorecard";
            location.hash = "#scorecard";
            break;
        }
      }
    },
    updateLocation(hash) {
      location.hash = hash;
    },
    retrieveData() {
      this.routeName = this.$route.name;
      if (this.routeName == "scorecards-v2-calls") {
        let params = this.getParams();
        this.retrieveAllCallScorecardDataV2(params);
        this.retrieveScorecard(params);
        this.retrieveScorecardDistributions(params);
        this.retrieveCallLogSkeleton(params);
      }
    },
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
    formatScore(score) {
      score = score ? score : 0; // prevents NaN
      return Math.round(score * 100);
    },
    formatPhoneNumber(number) {
      if (number == undefined) {
        return "";
      }
      return number.length == 10
        ? "(" +
            number.slice(0, 3) +
            ") " +
            number.slice(3, 6) +
            "-" +
            number.slice(6)
        : number;
    },
    getParams() {
      let paramObj = {
        scorecard_id: this.$route.params.scorecardId,
        dynalabel_id: this.$route.params.dynalabelId,
        agent_id: this.$route.params.agentId,
        grouping: this.getUnit,
        start_date: moment(this.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.end_date).format("YYYY-MM-DD"),
        distributions: this.$route.params.agentId == 0 ? "team" : "agent",
        page: this.pageNum,
        page_size: this.pageSize,
        search_calls: this.search ? this.search.trim() : this.search,
        order_by: this.orderBy,
        order_direction: this.orderDirection,
        cat_sort_id: this.categorySortId,
        users: this.userDataList,
        call_type: this.$store.getters["filters/scorecardAdjustments"] || "all"
      }

      // include audit info if user is proxying
      if(this.userData.id != this.userData.csrep_userid && this.userData.privileges.callFinderManageSearches) {
        paramObj.get_audit_info = true
      }

      return paramObj
    },
    resetData() {
      this.pageNum = 1;
      this.pageSize = 10;
    },
    getDateRangeString() {
      return (
        moment(this.start_date).format("M/D/YYYY") +
        " - " +
        moment(this.end_date).format("M/D/YYYY")
      );
    },
    hideScheduledExportsModal(hideScheduledExportsModal) {
      this.showScheduledExportsModal = hideScheduledExportsModal;
    },
    toggleExpansion() {
      this.showStats = !this.showStats
    },
    killAudio() {
      this.$root.$emit("KILL_AUDIO");
    }
  },
  mounted() {
    if (this.userDataLoadingStatus == "loaded") {
      this.retrieveAuthorizedUserListData(this.userData.id);
      this.setFavoriteScorecardTab(
        Number(CacheHandler.getItem("favorite_scorecard_tab") || 0)
      );
      this.getLocation();
      this.lastFav = this.favoriteScorecardTab;
    }
    this.$root.$on("DATE_RANGE_UPDATED", () => {
      this.$router
        .push({
          query: Object.assign(
            {},
            this.$route.query,
            {
              start_date: String(
                moment(this.$store.getters["dateRange/start_date"]).format(
                  "YYYY-MM-DD"
                )
              )
            },
            {
              end_date: String(
                moment(this.$store.getters["dateRange/end_date"]).format(
                  "YYYY-MM-DD"
                )
              )
            }
          ),
          hash: location.hash,
        })
        .catch(err => {
          this.err = err;
        })
        if(this.$refs.hasOwnProperty('trendTab')&&this.$refs['trendTab'].hasOwnProperty('reMount')) {
          this.$refs.trendTab.reMount();
        }
        this.getLocation();
        this.closeTabs();
        if (this.userDataListLoadingStatus == "loaded") {
          this.retrieveData();
        }
    });
    if (this.userDataListLoadingStatus == "loaded") {
      this.retrieveData();
      this.loadPresentationData();
    }
  },
  beforeDestroy() {
    this.resetData();
  },
  destroyed() {
    this.killAudio()
  },
  watch: {
    userDataLoadingStatus: function(status) {
      if (status == "loaded") {
        this.retrieveAuthorizedUserListData(this.userData.id);
        this.setFavoriteScorecardTab(
          Number(CacheHandler.getItem("favorite_scorecard_tab") || 0)
        );
        this.getLocation();
        this.lastFav = this.favoriteScorecardTab;
      }
    },
    userDataListLoadingStatus: function(status) {
      if (status == "loaded") {
        this.retrieveData();
        this.loadPresentationData();
      }
    }
  }
};
</script>

<style scoped>
.v-data-table.row-height-consistent td
{
  height: 3.125rem !important;
}
</style>
<style scoped>
  a[role="tab"]:not(.v-tab--active) {
    border-bottom: 1px rgba(0, 0, 0, 0.5) dotted !important;
  }
  .main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: 100%;
}
.stats-container {
  flex-shrink: 1;
  margin-left: 1rem;
  padding-top: 0;
}
.tabs-container {
  flex-grow: 1;
  padding-left: 1rem;
  margin-right: 2rem;
  overflow: auto;
}
.expand-button {
  text-align: center;
}
.collapsed-text {
  margin-top: 2rem;
  transform: rotate(90deg);
  font-weight: 700;
}

::v-deep .v-data-footer {
    margin-bottom: 4rem;
}

</style>
