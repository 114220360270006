<template>
  <v-hover>
    <div class="elevation-2 mb-1" :style="outerCategoryBGColor">
      <v-hover v-slot="{ hover }">
        <v-card elevation="0" :style="innerCategoryBGColor">
          <v-row>
            <v-col cols="11">
              <v-text-field
                ref="titleField"
                :rules="titleRules"
                v-model="category.category_title"
                counter
                dense
                outlined
                :maxlength="maxNameLength"
                :hint="categoryHint"
                persistent-hint
                label="Category Name"
                placeholder="category name goes here"
                @input="updateTitle"
                :error="category.title_error"
                background-color="rgba(204, 204, 255, .1)"
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn
                @click="expandAll"
                color="blue-grey"
                class="ma-2 white--text"
                v-if="!expanded"
                x-small
              >
                Expand
                <span title="Expand editor"> </span>
              </v-btn>
              <v-btn
                @click="collapseAll"
                color="blue-grey"
                class="ma-2 white--text"
                v-if="expanded"
                x-small
              >
                Collapse
              </v-btn>
            </v-col>
          </v-row>
        </v-card></v-hover
      >
      <div v-if="expanded" v-bind:collapseAll="collapseAll">
        <category-editor
          :selectedDynaLabels="categoryInfo.dynalabels"
          :originalInfo="categoryInfoOrig"
          :scorecardId="scorecardId"
          :selectedThreshold="Number(category.threshold)"
          :selectedScoringRule="Number(category.scoring_rule)"
          :category="categoryInfo"
          :categoryOrig="category"
          :categoryCount="categoryCount"
          :categoryManualScoring="Number(category.manual_scoring)"
          :mediaType="mediaType"
          @updateCancelProp="updateCancelProp"
          @saveCategory="saveCategoryToScorecard"
          @deletedCategory="deletedCategory"
          @updateDynalabelSelections="updateCategoryDynalabels"
          @updateThreshold="updateCategoryThreshold"
          @updateThresholdPassFail="updateCategoryThresholdPassFail"
          @updateThresholdCount="updateCategoryThresholdCount"
          @updateWeight="updateCategoryWeight"
          @subCategoryAdded="addOrDeleteSubCategory"
          @updateManualScoring="updateCategoryManualScoring"
          @updateManualScoringChoice="updateManualScoringChoice"
          @updateScoringRule="updateCategoryScoringRule"
          @updateSubcategoryScoringType="updateSubcategoryScoringType"
          @cancelSelections="cancelSelections"
        >
        </category-editor>
      </div>

      <transition-group name="sub_categoryfade">
        <div
          v-for="subCategory in category.sub_categories"
          :key="subCategory.id"
          :draggable="canDragAndDrop && category.sub_categories.length > 1"
          @dragstart="startDrag($event, subCategory)"
          @dragend="clearItemDragged()"
          :class="
            canDragAndDrop && category.sub_categories.length > 1
              ? ' draggable'
              : ''
          "
        >
          <div class="dropzone-positioning">
            <drop-zone
              v-if="
                canDragAndDrop &&
                  category.sub_categories.length > 1 &&
                  !dropZoneNums.includes(subCategory.sort_order - 0.5)
              "
              :dropZoneNumber="subCategory.sort_order - 0.5"
              :sortOrder="itemDragSortOrder"
              :bottom="false"
              :type="'subcategory'"
              :scorecardId="scorecardId"
              :category="category"
              :subCategories="category.sub_categories"
              :itemBeingDragged="itemDragging"
              @dropCompleteSetPublish="setPublishOn"
            ></drop-zone>
            <div class="subcategory-div-display">
              <subcategory-display
                :subCategory="subCategory"
                :scoringType="subcatScoringType(subCategory.scoring_type)"
                :expanded="subCategory.expanded"
                :categoryId="Number(category.id)"
                :scorecardId="Number(scorecardId)"
                :subCategoryCount="category.sub_categories.length"
                :categoryScoringRule="category.scoring_rule"
                :totalWeight="subcategoriesTotalWeight"
                :lastSubCatId="
                  Number(
                    category.sub_categories[category.sub_categories.length - 1]
                      .id
                  )
                "
                :catgoryManualScoring="Number(category.manual_scoring)"
                @updateSubCategory="updateSubCategory"
                @updateSubCategoryExpanded="updateSubCategoryExpanded"
                @deleteSubCategory="addOrDeleteSubCategory"
              ></subcategory-display>
            </div>
          </div>
        </div>
      </transition-group>
      <div class="dropzone-positioning">
        <drop-zone
          v-if="
            canDragAndDrop &&
              category.sub_categories.length > 1 &&
              !dropZoneNums.includes(lastSubCatSortOrder + 0.5)
          "
          :dropZoneNumber="lastSubCatSortOrder + 0.5"
          :sortOrder="itemDragSortOrder"
          :bottom="true"
          :type="'subcategory'"
          :scorecardId="scorecardId"
          :category="category"
          :subCategories="category.sub_categories"
          :itemBeingDragged="itemDragging"
          @dropCompleteSetPublish="setPublishOn"
        >
        </drop-zone>
      </div>
      <confirm ref="confirm"> </confirm>
      <h5 v-if="!properSearches && mySearches" class="d-inline  mt-4 ml-4">
        <sup class="footnote">*</sup> Each automated subcategory needs at least
        one search selected
      </h5>
    </div>
  </v-hover>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CategoryEditor from "./CategoryEditor.vue";
import SubcategoryDisplay from "./SubcategoryDisplay.vue";
import Confirm from "../Widgets/Confirm.vue";
import DropZone from "./DropZone.vue";

export default {
  props: {
    expanded: Boolean,
    category: Object,
    scorecardId: Number,
    cancel: Boolean,
    categoryCount: Number,
    canDragAndDrop: Boolean,
    properSearches: Boolean,
    categoriesWithoutSearches: Array,
    totalWeight: Number,
    categories: Array,
    mediaType: String
  },
  components: {
    CategoryEditor,
    SubcategoryDisplay,
    Confirm,
    DropZone
  },
  data() {
    return {
      maxNameLength: 120,
      title: this.category.category_title,
      categoryInfo: Object.assign({}, this.category),
      categoryInfoOrig: Object.assign({}, this.category),
      titleRules: [
        value => !!value || "A unique title is required.",
        value => {
          if (!value) {
            return true;
          } else {
            return (
              !this.categories.some(item => {
                return (
                  item.id != this.category.id &&
                  item.category_title.trim().toUpperCase() ==
                    value.trim().toUpperCase()
                );
              }) || "Titles must be unique."
            );
          }
        }
      ],
      itemDragging: "",
      itemDragSortOrder: null,
      disabledDropZone: false,
      dropZoneNums: [],
      subcategoriesTotalWeight: 0
    };
  },
  computed: {
    ...mapGetters("scorecardDefinitions", [
      "originalCategoryInfo",
      "scorecardDefinitionsList"
    ]),
    ...mapGetters("resources", ["searchData", "searchDataLoadingStatus"]),
  
    lastSubCatSortOrder() {
      let lastSubCat = 0;
      this.categoryInfo.sub_categories.forEach(element => {
        lastSubCat = element.sort_order;
      });
      return lastSubCat;
    },
    mySearches() {
      return (
        this.categoriesWithoutSearches.includes(this.category.id) &&
        this.category.manual_scoring != 1
      );
    },
    categoryHint() {
      if (!this.expanded) {
        if (this.category.scoring_rule == 0) {
          return (
            "Category Weight = " +
            this.category.weight +
            " (" +
            this.getCategoryPercentage() +
            ")"
          );
        } else {
          let verb = this.category.threshold_passfail == 1 ? "Fail" : "Pass";
          if (this.category.threshold_count == null) {
            return `${verb} when all subcategories are found`;
          } else {
            return `${verb} when ${
              this.category.threshold_count
            } or more subcategories are found`;
          }
        }
      } else {
        return "";
      }
    },
    outerCategoryBGColor() {
      return this.expanded
        ? "background-color: rgba(179, 212, 252, .08); border-top: 1.5rem solid rgba(179, 212, 252, .5);"
        : "border-top: 1.5rem solid rgba(179, 212, 252, .5);";
    },
    innerCategoryBGColor() {
      return this.expanded ? "background-color: rgba(179, 212, 252, .08);" : "";
    }
  },
  methods: {
    ...mapActions({
      reSortSubCategories: "scorecardDefinitions/reSortSubCategories"
    }),
    subcatScoringType(scoringType) {
      //we need to default to a scoring type of manual if the user does not have any searches tied to them (manual is 1)
      return this.searchData.length < 1 ? 1 : Number(scoringType);
    },
    getCategoryPercentage() {
      let percent = 0;
      if (this.totalWeight > 0) {
        percent = this.categoryInfo.weight / this.totalWeight;
      }
      return Math.round(percent * 100) + "%";
    },
    clearItemDragged() {
      this.itemDragging = "";
      this.itemDragSortOrder = null;
      this.dropZoneNums = [];
      this.$emit("disabledDropZone", false);
    },
    updateCancelProp(info) {
      this.categoryInfo.cancel = info;
      let params = {
        id: this.category.id,
        scorecard_id: this.scorecardId,
        category_id: this.category.id,
        category_info: this.categoryInfo,
        category_title: this.category.category_title
      };
      this.$emit("categoryUpdated", params);
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("subCatID", item.id);
      this.itemDragging = "subcategory";
      this.itemDragSortOrder = item.sort_order;
      this.$emit("disabledDropZone", true);
      this.dropZoneNums.push(item.sort_order - 0.5);
      this.dropZoneNums.push(item.sort_order + 0.5);
      this.$emit("updateSubCategory");
    },
    updateSubCategory(info) {
      let subCategory = this.categoryInfo.sub_categories.find(
        x => x.id == info.id
      );
      if (subCategory != undefined) {
        subCategory.searches = info.sub_category_info.searches;
        subCategory.weight = Number(info.sub_category_info.weight);
        subCategory.sub_category_title = info.sub_category_title;
        subCategory.cancel = info.sub_category_info.cancel;
        this.calculateTotalSubcategoryWeight();
        let params = this.getParams();
        this.$emit("categoryUpdated", params);
        this.$emit("updateSubCategory");
      }
    },
    updateSubcategoryScoringType(scoring_type) {
      if (scoring_type != this.categoryInfo.manual_scoring) {
        this.categoryInfo.cancel = true;
      }
      this.categoryInfo.manual_scoring = scoring_type;
      this.categoryInfo.sub_categories.forEach(element => {
        element.scoring_type = scoring_type;
      });

      this.saveCategoryToScorecard();
      this.$emit("updateSubCategory");
    },
    updateSubCategoryExpanded(info) {
      let subCategory = this.categoryInfo.sub_categories.find(
        x => x.id == info.id
      );
      if (subCategory != undefined) {
        subCategory.expanded = info.value;
        subCategory.cancel = true;
        let params = this.getParams();
        this.$emit("updateSubCategoryExpand", params);
      }
    },
    addOrDeleteSubCategory(id) {
      if (id) {
        this.$emit("pushCategoryWithoutSearches", id);
      }
      this.calculateTotalSubcategoryWeight();
      this.$emit("updateSubCategory");
    },
    cancelSelections(info) {
      this.categoryInfo = Object.assign({}, info);
      this.categoryInfo.dynalabels = info.dynalabels;
      this.categoryInfo.cancel = false;

      let params = {
        id: this.category.id,
        scorecard_id: this.scorecardId,
        category_id: this.category.id,
        category_info: this.categoryInfo,
        category_title: this.category.category_title
      };
      this.$emit("categoryUpdated", params);
    },
    updateCategoryDynalabels(dynalabels) {
      this.categoryInfo.dynalabels = dynalabels.map(obj => ({
        dynalabel_id: obj.id,
        new: obj.new,
        id: obj.db_id
      }));

      let params = {
        id: this.category.id,
        scorecard_id: this.scorecardId,
        category_id: this.category.id,
        category_info: this.categoryInfo,
        category_title: this.category.category_title
      };
      this.$emit("categoryUpdated", params);
    },
    updateCategoryScoringRule(rule) {
      this.categoryInfo.threshold_passfail = 0;
      this.categoryInfo.threshold_count = null;
      if (rule != this.categoryInfo.scoring_rule) {
        this.categoryInfo.cancel = true;
        this.categoryInfo.scoring_rule = rule;
      }
      this.categoryInfo.scoring_rule = rule;
    },
    updateCategoryThreshold(thresholdType) {
      if (thresholdType != this.categoryInfo.threshold) {
        this.categoryInfo.cancel = true;
      }
      this.categoryInfo.threshold = thresholdType;
    },
    updateCategoryThresholdPassFail(passFailLimit) {
      if (passFailLimit != this.categoryInfo.threshold_passfail) {
        this.categoryInfo.cancel = true;
      }
      this.categoryInfo.threshold_passfail = passFailLimit;
    },
    updateCategoryThresholdCount(count) {
      if (!count) {
        this.categoryInfo.threshold_count = null;
      } else if (count < 0) {
        this.categoryInfo.threshold_count = 0;
      } else if (count > 10) {
        this.categoryInfo.threshold_count = 10;
      } else {
        this.categoryInfo.threshold_count = count;
      }
    },
    updateCategoryWeight(weight) {
      if (weight != this.categoryInfo.weight) {
        this.categoryInfo.cancel = true;
      }
      if (!weight || weight < 0) {
        this.categoryInfo.weight = 0;
      } else if (weight > 10) {
        this.categoryInfo.weight = 10;
      } else {
        this.categoryInfo.weight = weight;
      }
    },
    updateCategoryManualScoring(manualScoring) {
      if (manualScoring != this.categoryInfo.manual_scoring) {
        this.categoryInfo.cancel = true;
      }
      this.categoryInfo.manual_scoring = manualScoring;
    },
    updateManualScoringChoice(manualScoringChoice) {
      if (manualScoringChoice != this.categoryInfo.manual_scoring_choice) {
        this.categoryInfo.cancel = true;
      }
      this.categoryInfo.manual_scoring_choice = manualScoringChoice;
      this.saveCategoryToScorecard();
    },
    getParams() {
      return {
        id: this.category.id,
        scorecard_id: this.scorecardId,
        category_id: this.category.id,
        category_info: this.categoryInfo,
        category_title: this.category.category_title
      };
    },
    updateTitle() {
      let params = this.getParams();
      params.only_title = true;
      this.$emit("checkTitleErrors");
      this.$emit("categoryUpdated", params);
    },
    deletedCategory() {
      this.$emit("deletedCategory");
    },
    saveCategoryToScorecard() {
      let params = this.getParams();
      this.$emit("categoryUpdated", params);
    },
    collapseAll() {
      this.categoryInfo.expanded = false;
      this.$emit("updateCategoryExpanded", {
        value: this.categoryInfo.expanded,
        id: this.categoryInfo.id
      });
    },
    expandAll() {
      this.categoryInfo.expanded = true;
      this.$emit("updateCategoryExpanded", {
        value: this.categoryInfo.expanded,
        id: this.categoryInfo.id
      });
    },
    expandOrCollapse() {
      this.categoryInfo.expanded = this.category.expanded;
      this.$emit("updateCategoryExpanded", {
        value: this.categoryInfo.expanded,
        id: this.categoryInfo.id
      });
      let params = {
        id: this.category.id,
        scorecard_id: this.scorecardId,
        category_id: this.category.id,
        category_info: this.categoryInfo,
        category_title: this.category.category_title
      };
      this.$emit("categoryUpdated", params);
    },
    areSearchesProper() {
      this.$emit("checkSearches", this.properSearches);
    },
    calculateTotalSubcategoryWeight() {
      this.subcategoriesTotalWeight = 0;
      this.category.sub_categories.forEach((sub_category, index) => {
        this.subcategoriesTotalWeight += sub_category.weight;
      });
    },
    setPublishOn() {
      this.$emit("setPublishOn", true);
    }
  },

  mounted() {
    this.areSearchesProper();
    this.calculateTotalSubcategoryWeight();
    this.categoryInfo.cancel = this.category.cancel;
  },
  watch: {
    expanded: "expandOrCollapse",
    properSearches: "areSearchesProper"
  }
};
</script>

<style>
fieldset {
  -moz-border-radius: 0.2rem;
  border-radius: 0.2rem;
  -webkit-border-radius: 0.2rem;
  border: 1px solid #c2c2c2;
  padding: 0.4rem;
}

legend {
  margin-left: 0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.3rem;
  font-size: 0.8rem;
  color: #bababa;
}

.dropzone-positioning {
  position: relative;
}

.subcategory-div-display {
  z-index: 1000;
}
</style>
