import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { authentication } from "./modules/authentication.js";
import { dateRange } from "./modules/dateRange.js";
import { deliveries } from "./modules/deliveries.js"
import { filters } from "./modules/filters.js";
import { presentation } from "./modules/presentation.js";
import { insights } from "./modules/insights.js";
import { scorecardsV2 } from "./modules/scorecardsV2.js";
import { scorecardDefinitions } from "./modules/scorecardDefinitions.js";
import { dynaLabels } from "./modules/dynaLabels.js";
import { accessRoles } from "./modules/accessRoles.js";
import { searchQuery } from "./modules/searchQuery.js";
import { setup } from "./modules/setup.js";
import { transcript } from "./modules/transcript.js";
import { users } from "./modules/users.js";
import { resources } from "./modules/resources.js";
import { notifications } from "./modules/notifications.js";
import { scorecardsV2Aggregates } from "./modules/scorecardsV2Aggregates.js";
import { scorecardsV2Calls } from "./modules/scorecardsV2Calls.js";

export const store = new Vuex.Store({
  modules: {
    authentication,
    dateRange,
    deliveries,
    filters,
    presentation,
    insights,
    scorecardsV2,
    scorecardsV2Aggregates,
    scorecardsV2Calls,
    scorecardDefinitions,
    dynaLabels,
    accessRoles,
    notifications,
    searchQuery,
    setup,
    transcript,
    users,
    resources,
  }
});
