<template>
  <v-content style="height: calc(100%); max-height: calc(100%);" id="my-content">
    <v-container fluid pa-0 style="height: 100vh; max-height: calc(100%);">
      <v-row style="height: 100vh; max-height: calc(100%);">
        <v-col cols="2" class="pb-12">
      <v-navigation-drawer
            width="auto"
            style="height: 100%; max-height: calc(100%); padding: 0 0 20px 10px;"
          >
        <v-list nav dense>
          <v-subheader>FAQ MENU</v-subheader>
              <v-list-item-group
              v-model="selectedItem"
              color="primary"
              mandatory
              >
                <v-list-item>
                  <v-list-item-title>Scorecards</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Insights</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Download Reports & <br /> Scheduled Deliveries</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Call Transcript</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Icons</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>General</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      </v-col>
      <v-col v-show="selectedItem == 0" class="pb-12">
        <f-a-q-card :items = "scorecardOutputItems" :title = "'Scorecards'"></f-a-q-card>
      </v-col>
      <v-col v-show="selectedItem == 1" class="pb-12">
        <f-a-q-card :items = "insightOutputItems" :title = "'Insights'"></f-a-q-card>
      </v-col>
      <v-col v-show="selectedItem == 2" class="pb-12">
        <f-a-q-card :items = "drsdItems" :title = "'Download Reports & Scheduled Deliveries'"></f-a-q-card>
      </v-col>
      <v-col v-show="selectedItem == 3" class="pb-12">
        <f-a-q-card :items = "callTranscriptItems" :title = "'Call Transcript'"></f-a-q-card>
      </v-col>
      <v-col v-show="selectedItem == 4" class="pb-12">
        <f-a-q-card :items = "iconItems" :title = "'Icons'"></f-a-q-card>
      </v-col>
      <v-col v-show="selectedItem == 5" class="pb-12">
        <f-a-q-card :items = "generalItems" :title = "'General'"></f-a-q-card>
      </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>

  import FAQCard from "@/components/FAQCard.vue";

export default{
  components: {
      FAQCard,
    },

  data () {
		return {
      selectedItem: parseInt(this.$route.params.pageid) || 0,
      insightOutputItems: [
        { question: "What is an insight?",
          answer: "An insight is a collection of calls along with their related metrics. Each individual call metric is summarized per agent and per dynamic label, depending on the number of dynamic labels selected within the insight definition. <br /> <br />" +
                  "Example: If an insight definition is tied to two dynamic labels – one tied to “New Agents” and another tied to “Existing Agents” and shared with an access role named “QA Lead” and “Executive”, all users defined within the QA Lead and Executive access role will automatically gain access to insights summarizing New Agents versus Existing Agents, the agent summaries for each group, as well as their individual calls. "
        },
        { question: "Are there any recommended or suggested best practices related to insights?",
          answer: "Insights allow you to focus on agent soft skills. One of the most common challenges when clients first start using speech analytics is defining what a successful call looks like. That’s why we recommend starting with Insights to identify “outliers” and get a sense of which agents need more attention. Then compare and sort by dynamic labels or agents. Finally sort by the desired metric to obtain concrete examples and identify coaching opportunities."
        },
        { question: "How is Talk Time calculated?",
          answer: "Per Call? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Talk time is calculated based on the duration of the audio recording. <br /> <br />" +
                  "Per Agent? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Talk time is calculated by the average of a given agent’s individual talk times and is rounded to the nearest second. <br /> <br />" +
                  "Per Dynamic Label? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Talk time is calculated by the average individual talk times within a given dynamic label and is rounded to the nearest second."
        },
        { question: "How should I interpret longer or shorter talk times? ",
          answer: "Longer calls can reveal agents that are uncomfortable with their subject material, combative customers, or complex issues. Short calls can reveal call avoidance, rushed agents, or efficient agents."
        },
        { question: "How are Calls/Day calculated?",
          answer: "Per Agent? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Total calls for a given agent divided by the count of days within the selected date range with a minimum of one call. <br /> <br />" +
                  "Per Dynamic Label? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Total calls for a given dynamic label divided by the count of days within the selected date range with a minimum of one call divided by the count of agents with at least one call during the selected date range."
        },
        { question: "How is sentiment calculated?",
          answer: "Per Call? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "The overall average sentiment for the first and second half of each call is proprietary. There are underlying weights attached to each segment’s sentiment score, which are then averaged across the appropriate talk time period. The first and second half of each call is determined by talk time. The two call halves are then averaged to determine the overall sentiment call score. For 2-channel calls, the agent and customer channels are also evaluated separately in order to compile the Agent and Customer-specific metrics. <br /> <br />" +
                  "Per Agent? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "All agent-related call sentiment scores (1st half, 2nd half, Overall) are averaged to calculate the agent level sentiment score. For agents with affiliated 2-channel calls, there are additional insight scores provided for Agent and Customer-specific sentiment metrics.  <br /> <br />" +
                  "Per Dynamic Label? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "All dynamic label-related call sentiment scores (1st half, 2nd half, Overall) are averaged to calculate the dynamic label level sentiment score. For dynamic labels with affiliated 2-channel calls, there are additional insight scores provided for Agent and Customer-specific sentiment metrics. <br /> <br />"
        },
        { question: "How should I interpret positive or negative sentiment?",
          answer: "Positive sentiment typically means that the customer's experience has been satisfactory or even excellent. It might manifest as appreciative or complimentary remarks, agreement with the agent's suggestions, etc. Negative sentiment often suggests dissatisfaction with the product, service, or the call center experience itself. It may be expressed through words or phrases that imply frustration, impatience or criticism. <br /><br />" +
                  "It’s important to note that in some industries, certain terms may carry a different sentiment than they would in general use. For example, in the healthcare industry, words like “pain” or “sickness” might generally be seen as negative. However, in the context of a conversation between a patient and a healthcare provider, they might simply be neutral descriptors of the patient's condition. By customizing your sentiment analysis tool, you can ensure a more accurate and useful analysis of sentiment particular to your unique use case."
        },
        { question: "Can I customize which words and phrases reflect positive, negative, or neutral sentiment?",
          answer: "Clients can customize specific words and phrases that they consider positive, negative, or neutral. CallFinder recommends customizing sentiment scores once we have processed 30 days of call volume."
        },
        { question: "How is emotion calculated? ",
          answer: "Emotion is calculated based on tone and the combination of words and phrases found in the transcript. Please note, if a client has customized sentiment, it will affect emotion scores as well. <br /><br />" +
                  "Per Call? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "The overall average emotion for the first and second half of each call is proprietary. There are underlying weights attached to each segment emotion score, which are then averaged across the appropriate talk time period. The first and second half of each call is determined by talk time. The two call halves are then averaged to determine the overall emotion call score.  <br /> <br />" +
                  "Per Agent? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "All agent-related call emotion scores (1st half, 2nd half, Overall) are averaged to calculate the agent level emotion score. For agents with affiliated 2-channel calls, there are additional insight scores provided for Agent and Customer-specific emotion metrics. <br /> <br />" +
                  "Per Dynamic Label? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "All dynamic label-related call emotion scores (1st half, 2nd half, Overall) are averaged to calculate the dynamic label level emotion score. For dynamic labels with affiliated 2-channel calls, there are additional insight scores provided for Agent and Customer-specific emotion metrics. <br /> <br />"
        },
        { question: "How should I interpret positive or negative emotion?",
          answer: "Positive emotion often indicates a satisfactory or beyond satisfactory customer experience. These emotions can be expressed through upbeat tone, gratitude, laughter, or complimentary language. The customer is likely to have had their issues resolved and felt heard and valued. The customer is more likely to remain loyal, engage in positive word-of-mouth marketing, and provide high customer satisfaction scores. The agent likely demonstrated strong customer service skills, such as empathy, effective problem-solving, and strong communication. <br /><br />" +
                  "Negative emotion is often expressed through frustration, anger, sadness, or critical language. They can indicate several things: The customer's issue might not have been resolved to their satisfaction, or they may feel unheard or unvalued. The customer may be more likely to disengage, spread negative feedback, or even end their relationship with the company. The call center agent may not have effectively addressed the customer's concerns, or they might lack some key customer service skills. Negative emotions could also be due to factors outside the call center's control, such as larger issues with the product or service, or personal issues affecting the customer."
        },
        { question: "How is silence calculated? ",
          answer: "Per Call? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Any segments of low audio volume longer than a standard pause are summed up across the call. The total silence is then divided by talk time. <br /> <br />" +
                  "Per Agent? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "The sum of all call silence segments for a given agent divided by the sum of the talk time for the same agent.  <br /> <br />" +
                  "Per Dynamic Label? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "The sum of all call silence segments for a given dynamic label divided by the sum of the talk time for the same dynamic label.  <br /> <br />"
        },
        { question: "How should I interpret a high or low percentage of silence?",
          answer: "High silence may identify extensive holds with low audio volume or lack of product/service knowledge. Low silence may identify a poor listener.  Alternatively, low silence could identify an attentive agent."
        },
        { question: "Why are there periods of silence in my recording unrelated to holds or agent/customer pauses?",
          answer: "If CallFinder redacts audio as part of your customized integration, you may notice periods of silence which correlate to periods of redacted information."
        },
        { question: "How is overtalk calculated?",
          answer: "Per Call? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Overtalk per call is calculated by the sum of the overlapping agent and customer speaking segments.  The total overtalk is then divided by talk time. <br /> <br />" +
                  "Per Agent? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Overtalk for a given agent is calculated by the sum of overtalk time segment for that agent divided by the sum of the talk time for the same agent. <br /> <br />" +
                  "Per Dynamic Label? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Overtalk for a given dynamic label is calculated by the sum of overtalk time segments for that dynamic label divided by the sum of the talk time for the same dynamic label. <br /> <br />"
        },
        { question: "How should I interpret a high or low percentage of overtalk?",
          answer: "A high percentage of overtalk could be seen as an indicator of poor communication, which may be due to various factors. For example, it could be caused by a lack of listening skills, impatience, or misunderstanding. A low percentage of overtalk is generally considered good. This is because it means both the agent and customer are allowing each other to speak and express their points. Such conversations are usually more effective. <br /> <br />" +
                  "It's worth noting, however, that context is important. Some level of overtalk might be acceptable or even necessary in certain situations, such as when an agent needs to interrupt a customer who's going off-topic, or when both parties are displaying positive enthusiasm."
        },
        { question: "How is speaking time calculated?",
          answer: "Per Call? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Any segments of audible speech comprised of more than a few words are summed across the call.  The total speaking time is then divided by talk time.  <br /><br />" +
                  "Per Agent? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Speaking time is calculated by the sum of speaking time segments for a given agent divided by the total talk time for the same agent.  <br /><br />" +
                  "Per Dynamic Label? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Speaking time is calculated by the sum of all speaking time segments for a given dynamic label divided by the total talk time for the same dynamic label. <br /><br />"
        },
        { question: "How should I interpret a high or low percentage of speaking time?",
          answer: "High speaking time may identify extensive holds with audio volume. Low speaking time may identify a lack of product/service knowledge."
        },
        { question: "Why do my silence and speaking time per call totals not equal 100%?",
          answer: "Silence and speaking time are calculated based on different audio levels and segment lengths."
        },
        { question: "Why is my overtalk metric blank? ",
          answer: "Only dual or 2-channel calls are eligible for overtalk metrics."
        },
        { question: "Why is my agent/customer information blank? ",
          answer: "Only dual or 2-channel calls are eligible for agent/customer metrics."
        },
        { question: "How are the agent/customer breakdowns calculated?",
          answer: "Silence? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "If the agent speaks following a period of silence or is the last to speak prior to a period of silence before the call is disconnected, the agent is credited with the silence. If the customer speaks following a period of silence or is the last to speak prior to a period of silence before the call is disconnected, the customer is credited with the silence.<br /> <br />" +
                  "Overtalk? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "If the agent is the second person to speak during overlapping agent/customer segments, the agent is credited with the overtalk. If the customer is the second person to speak during overlapping agent/customer segments, the customer is credited with the overtalk. <br /> <br />" +
                  "Speaking Time? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Segments of audible speech comprised of more than a few words are calculated separately for the agent and customer channels. The agent portion is calculated based on the agent channel speaking time divided by the sum of the agent and customer channel speaking segment time. The customer portion is calculated based on the customer channel speaking time divided by the sum of the agent and customer channel speaking segment time."
        },
        { question: "Why is the sum of my call counts across dynamic labels greater than the “view all dynamic labels” row?",
          answer: "A call can belong to more than one dynamic label. The sum of the dynamic labels may add up to more than the “All Dynamic Labels” total."
        },
        { question: "Why am I unable to locate calls of shorter or longer talk times?",
          answer: "Insight definitions allow for specified minimum and maximum talk time settings. Please refer to the Insight tile and hover over the information icon to determine whether any minimum or maximum talk times are included within the insight definition."
        },
        { question: "Why can’t I extend the date range beyond 31 days?",
          answer: "Date range selections are limited to 31 days to maintain ample processing speeds and optimize user experience."
        },
        { question: "Are there any tips to improve loading speeds when using the site?",
          answer: "We recommend using shorter date ranges when looking at large volumes of calls with no filters applied, less data to load will result in faster response times within the site."
        },
        { question: "Why can’t I locate a call using my client call identifier, customer #, or other identifiable information within insights?",
          answer: "The insight must contain your desired call within the scope of the insight definition and selected filters.  CallFinder recommends downloading the calls and referring to the dynamic label file if you wish to locate a call based on metadata information."
        },
        { question: "How can I narrow in on results and calls within insights that include or exclude specific phrases? ",
          answer: "Use the insight search filter to automatically enter desired search phrases and special search terms (refer to UI instructions for available options). If you have dual or 2-channel calls, you also have the option of searching the agent or customer channel. Once you launch an insight search, your results will include matched call counts by dynamic label and agent, along with the specific calls. " +
            "<br> <b>Note: When entering an Insight Search, keep in mind the search bar allows a maximum of 255 characters.</b>"
        },
        { question: "How long are my calls accessible within findmycalls.com?",
          answer: "Your data is accessible for 90 days beyond the call date.  If you wish to maintain a copy of an audio recording and transcript and are authorized by your administrator to export audio recording and transcripts, please refer to the Add to Export Queue icon.  See information contained within the Icons help section for further detail."
        }
      ],
      scorecardOutputItems: [
        { question: "What is a Scorecard?",
          answer: "A scorecard is a collection of calls along with their scored metrics.  Each individual call is summarized per agent and per dynamic label, depending on the number of dynamic labels selected within the scorecard definition. <br /> <br />" +
                  "Example: If a scorecard definition is tied to two dynamic labels – one tied to “New Agents” and another tied to “Existing Agents” and shared with an access role named “QA Lead” and “Executive”, all users defined within the QA Lead and Executive access role will automatically gain access to the scorecard summarizing New Agents versus Existing Agents, the agent summaries for each group, as well as their individual calls."
        },
        { question: "Are there any recommended or suggested best practices related to scorecards?",
          answer: "Scorecards allow you to focus on overall and individual category scores.  We recommend sorting by each score to identify the “outliers” and get a sense of which areas need more targeted attention.  Then compare and sort by dynamic labels or agents.  Finally sort by the overall score or specific category score and identify coaching opportunities."
        },
        { question: "How is Talk Time calculated?",
          answer: "Per Call? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Talk time is calculated based on the length of the audio recording. <br /> <br />" +
                  "Per Agent? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "The average of all individual talk times for a given agent for the selected filters. <br /> <br />" +
                  "Per Dynamic Label? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "The average of all individual talk times for a given dynamic label for the selected filters."
        },
        { question: "How are Calls/Day calculated?",
          answer: "Per Agent? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Total calls for a given agent divided by the count of days within the selected date range with a minimum of one call. <br /> <br />" +
                  "Per Dynamic Label? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Total calls for a given dynamic label divided by the count of days within the selected date range with a minimum of one call divided by the count of agents with at least one call during the selected date range."
        },
        { question: "How are Totals Calls calculated?",
          answer: "Per Agent? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Count of included calls for a given agent for the selected filters. <br /> <br />" +
                  "Per Dynamic Label? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Count of included calls for a given dynamic label for the selected filters."
        },
        { question: "How are the overall score and individual category scores calculated?",
          answer: "Per Call? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Individual overall call scores and category scores are calculated based on the customized scorecard definition.  Hover over the icons to the left of each category and subcategory to gain a more detailed understanding of the underlying scorecard calculations.   <br /> <br />" +
                  "Per Agent? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Overall call scores are calculated based on the average of all agent rounded call scores for the selected filters.  Category scores are calculated based on the average of all applicable category agent rounded call category scores for the selected filters. <br /> <br />" +
                  "Per Dynamic Label? <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Overall call scores are calculated based on the average of all dynamic label rounded call scores for the selected filters.  Category scores are calculated based on the average of all applicable category dynamic label rounded call category scores for the selected filters."
        },
        { question: "Why is the sum of my call counts across dynamic labels greater than the “view all dynamic labels” row?",
          answer: "A call can belong to more than one dynamic label. The sum of the dynamic labels may add up to more than the “All Dynamic Labels” total."
        },
        { question: "Why am I unable to locate calls of shorter or longer lengths?",
          answer: "Scorecard definitions allow for specified minimum and maximum talk time settings. Please refer to the Scorecard tile and hover over the information icon to determine whether any minimum or maximum talk times are included in the scorecard definition."
        },
        { question: "Why can’t I extend the date range beyond 31 days?",
          answer: "Date range selections are limited to 31 days to maintain ample processing speeds and optimize user experience."
        },
        { question: "Are there any tips to improve loading speeds when using the site?",
          answer: "We recommend using shorter date ranges when looking at large volumes of calls with no filters applied, less data to load will result i faster response times within the site."
        },
        { question: "Why can’t I locate a call using my external call identifier, customer #, or other identifiable information within scorecards? ",
          answer: "The scorecard must contain your desired call within the scorecard definition and selected filters.  CallFinder recommends downloading the calls and referring to the dynamic label file if you wish to locate a call based on metadata information."
        },
        { question: "Why are there more calls within the calls tab than what is referenced within the dynamic label summary, dynamic label scorecard, agent summary, or agent scorecard views?",
          answer: "Both included and excluded calls are included within the total calls calculation in the calls tab.  Sorting by call score will allow easy navigation to the excluded calls. "
        },
        { question: "How can I gain visibility into how many calls were subject to a specific category?",
          answer: "Go to the scorecard tab and click on the < to the left of the Category header."
        },
        { question: "How can I gain visibility into how many calls had subcategory hits?",
          answer: "Go to the scorecard tab and click on the < to the left of the Subcategory header."
        },
        { question: "I have visibility into more than one scorecard.  If I exclude or make adjustments to one scorecard, will the modifications carry over to the other(s)?",
          answer: "Excluded or adjusted calls are scorecard specific and will not carry over to other visible scorecards."
        },
        { question: "Why am I unable to exclude or modify a scorecard? ",
          answer: "Your user login must have the appropriate setting in order to exclude  or adjust a call scorecard.  Your administrator must authorize any setting changes."
        },
        { question: "How long are my calls accessible within findmycalls.com?",
          answer: "Your data is accessible for 90 days beyond the call date.  If you wish to maintain a copy of an audio recording and transcript, please refer to the Add to Export Queue icon.  See information contained within the Icons help section for further detail."
        },
      ],
      iconItems: [
        { question: "<span class='mdi mdi-folder-information-outline panelIcon'></span><span style='padding-left:6rem'>Custom Metadata</span>",
          answer: "This icon will bring you to the Custom Metadata pop-up specific to each Call ID.<br/><br/><em>The pop-up shows details such as Channel Count, Client Call Id, Direction, Language, and Talk Time.</em>"
        },
        { question: "<span class='mdi mdi-note-outline panelIcon'></span>&nbsp&nbsp&nbsp&nbsp<span class='mdi mdi-note-text-outline panelIconBlue'></span><span style='padding-left:3.2rem'>Notes</span>",
          answer: "This icon will bring you to the Notes pop-up specific to each Call ID<br/><br/><em>The notes icon will be gray if no notes are entered and will be blue if notes are availiable to read.</em>"
        },
        { question: "<span class='mdi mdi-tag-outline panelIcon'></span>&nbsp&nbsp&nbsp&nbsp<span class='mdi mdi-tag-text-outline panelIconBlue'></span><span style='padding-left:3.2rem'>Tags</span>",
          answer: "This icon will bring you to the tags pop-up specific to each Call ID.<br/><br/><em>The tag icon will be gray if no tags are selected and will be blue if there are tags selected.</em>"
        },
        { question: "<span class='mdi mdi-email-arrow-right-outline panelIcon'></span><span style='padding-left:6rem'>Email Transcript</span>",
          answer: "This icon will bring you to an Email popup that will have you fill out details to send the entire transcript link to an email address."
        },
        { question: "<span class='mdi mdi-newspaper-variant-multiple-outline panelIcon'></span><span style='padding-left:6rem'>Open Printable</span>",
          answer: "This icon will open a new tab with a Printable version of the Scorecard information you were viewing when you selected the button."
        },
        { question: "<span class='mdi mdi-comment-text-outline panelIcon'></span><span style='padding-left:6rem'>Open Transcript</span>",
          answer: "This icon will open a pop-up modal with the entire call Transcript that can be read while listening to the Audio of the call."
        },
        { question: "<span class='mdi mdi-tray-arrow-down panelIcon'></span><span style='padding-left:6rem'>Add to Export Queue</span>",
          answer: "This icon will add the Transcript to the Export Queue for downloading the individual call transcript."
        },
        { question: "<span class='mdi mdi-tray panelIcon'></span>&nbsp&nbsp&nbsp&nbsp<span class='mdi mdi-tray-full panelIcon'></span><span style='padding-left:3.2rem'>Export Queue</span>",
          answer: "This icon will bring you to the Export Queue list. This list will show a dropdown of the Processing/Downloading Exports from the site (both Immediate Downloads and Transcript Exports<br/><br/><em>The export queue icon will be an empty tray if the export queue is empty and a filled tray if the export queue has available files.</em>"
        },
        { titleIcon: "schedule-export-pdf",
          question: "<span style='padding-left:6rem'>Scheduled Deliveries</span>",
          answer: "This icon will bring you to a Scheduled Delivery list that will show all of the Scheduled Deliveries set up.<br/><br/><em>When clicked you will see the list with details such as the scorecard name and time of delivery</em>"
        },
        { question: "<span class='mdi mdi-dots-vertical panelIcon'></span><span style='padding-left:6rem'>Menu</span>",
          answer: "This icon will open an expanded menu to view further options such as downloading reports or scheduling deliveries."
        },
      ],
      drsdItems: [
        { question: "What does the Download Report or Scheduled Report Delivery option do?",
          answer: "The Download Report option will immediately export the call data you currently have selected in the UI, including the recognition of any selected filters.  The Scheduled Delivery option will allow you to automate the recurring delivery of the insight call data on a daily, weekly or monthly basis.  The data range will be adjusted automatically as recurring deliveries are sent.  However, any other selected filters will be recognized."
        },
        { question: "Where can I download a csv report or schedule a csv report delivery?",
          answer: "Downloads and scheduled deliveries are available via the Download Report or Schedule Report Delivery icons.  See information contained within the Icons help section for further detail.<br /><br />" +
                  "<span class='mdi mdi-dots-vertical-circle panelIconBlue'></span>"
        },
        { question: "Where can I schedule a pdf scheduled report delivery?",
          answer: "Pdf scheduled report deliveries are available within Scorecards and can be located within the scorecard tab.  They can be scheduled using the Schedule Report Delivery icon.  See information contained within the Icons help section for further detail.  If a scheduled report delivery is created for a scorecard representing more than one agent, a top 5/bottom 5 summary will be included in the pdf attachment along with an individual scorecard per agent." +
                  "<span><custom-icon class='panelIcon' name='schedule-export-pdf'></custom-icon></span>",
          icon: "schedule-export-pdf"
        },
        { question: "How many files are included in my report download or scheduled report delivery?",
          answer: "There are four separate files for each export containing all the information accessible from within the UI: <br /><br />" +
                  "Call Detail <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "The information contained in the call detail file will be identical to the UI, including columns such as date/time, customer, talk time and agent.  For Insights, additional insight specific metrics will be included such as sentiment, emotion, silence and overtalk.  For Scorecards, additional scorecard metrics will be included such as score and category scores.<br /><br />" +
                  "Custom Metadata <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "The information contained in the dynamic label file will include your call detail-related customized integration metadata, i.e. the same information visible within the Custom Metadata icon.<br /><br />" +
                  "Summary <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "The Summary file provides navigation detail (selected dynamic label/agent), date range, Insight/Scorecard Name, selected filters, etc.<br /><br />" +
                  "Tags <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                  "Any manually entered tags for your reported call detail will be contained in the tags file, i.e. the same information visible within the Tags icon)"
        },
        { question: "What happens if there’s no information so some of the file types?",
          answer: "If there is no data for one or more files included in each export or scheduled delivery, the file returned will show the header row without any additional information."
        },
        { question: "What will show in my reports?",
          answer: "The information provided in the download or scheduled delivery will align with the data on the screen.  Check the navigation on the top left of your screen before scheduling to ensure you are downloading or scheduling delivery of your desired data."
        },
        { question: "When is the data expected to be delivered for scheduled reports?",
          answer: "Monthly scheduled reports will be sent out on the 1st calendar day of each month and will contain the full previous months’ worth of data.  Weekly scheduled reports will be sent out each week on the selected day and will contain data from the last 7 days.  Daily scheduled reports will be sent out each day and will contain data from the last days’ worth of data."
        },
        { question: "Why does my scheduled delivery not align with what is currently visible in the UI even though no changes were made to the definition?",
          answer: "You should consider your customized integration handling when scheduling report deliveries.  Only fully processed calls will be included in your scheduled delivery.  Calls processed post-delivery will be accessible in the UI, but will not be included in your delivery.  Please consider a later delivery time if you continually run into misalignment."
        },
      ],
      callTranscriptItems: [
        { question: "How can I simultaneously listen while reading the transcript?",
          answer: "The transcript is automatically aligned with your audio recording. After selecting play, your transcript will automatically scroll as you listen to a call. You have the option to select play at each segment in any desired order. "
        },
        { question: "How can I locate a specific phrase within a transcript?",
          answer: "Use the Ctrl-F function to locate a phrase within a transcript."
        },
        { question: "What are the icons available at the top of each transcript?",
          answer:   "<span class='mdi mdi-folder-information-outline panelIcon'>&nbsp&nbsp</span> " +
                    "<span class='mdi mdi-note-outline panelIcon'>&nbsp&nbsp</span> " +
                    "<span class='mdi mdi-tag-outline panelIcon'>&nbsp&nbsp</span> " +
                    "<span class='mdi mdi-email-arrow-right-outline panelIcon'>&nbsp&nbsp</span> " +
                    "<span class='mdi mdi-newspaper-variant-multiple-outline panelIcon'>&nbsp&nbsp</span>" +
                    "<span class='mdi mdi-comment-text-outline panelIcon'>&nbsp&nbsp</span>" +
                    "<span class='mdi mdi-tray-arrow-down panelIcon'>&nbsp&nbsp</span> <br />" +
                    "From left to right: <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                    "Custom Metadata <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                    "Notes <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                    "Tags <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                    "Send Email <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                    "Open Printable Scorecard <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                    "Open Transcript <br /> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
                    "Add to Export Queue <br/> <br/>"+
                    "View Icon FAQ page for more information"
        },
        { question: "Why are there periods of silence in the middle of my audio recording?",
          answer: "If your CallFinder service includes redaction, you may notice periods of silence within a call recording.  Redaction will automatically eliminate all numbers from both the audio recording and transcript.  Exception to redacted numbers are ordinal numbers, percentages, times, prices, and short decimal numbers."
        },
      ],
      generalItems: [
        { question: "How long will my emailed transcripts/scorecard links remain available once they are sent?",
          answer: "The links will remain available for 30 days once sent from the site."
        },
        { question: "How long do I stay logged in for if I’m not using the site?",
          answer: "You remain logged into the site for 120 minutes before CallFinder logs you out for inactivity."
        },
        { question: "Which keyboard commands can be used in CallFinder?",
          answer: "<table><tr><td><b>Windows</b> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</td>" + "<td><b>Mac</b> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</td>" + "<td><b>Action</b></td></tr>"+
                  "<tr><td>Ctrl and P</td><td>⌘ and P</td><td>Print</td></tr><tr>"+
                  "<tr><td>Ctrl and F</td><td>⌘ and F</td><td>Find</td></tr><tr>"+
                  "<tr><td>Ctrl and +</td><td>⌘ and +</td><td>Zoom In</td></tr><tr>"+
                  "<tr><td>Ctrl and -</td><td>⌘ and -</td><td>Zoom Out</td></tr><tr>"+
                  "<tr><td>Ctrl and C</td><td>⌘ and C</td><td>Copy</td></tr><tr>"+
                  "<tr><td>Ctrl and V</td><td>⌘ and V</td><td>Paste</td></tr><tr>"+
                  "<tr><td>Ctrl and X</td><td>⌘ and X</td><td>Cut</td></tr><tr>"+
                  "<tr><td>Ctrl and Z</td><td>⌘ and Z</td><td>Undo Typing</td></tr><tr>"
        },
      ]
    }
  },
  methods: {

  }
}


</script>

<style>

  .panelIconBlue {
    color:rgb(25, 118, 210) !important;
    font-size: 1.8rem !important;
    text-align: center !important;
  }
  .panelIcon {
    color:rgba(0,0,0,.54);
    font-size: 1.8rem !important;
    text-align: center !important;
  }
  .v-slide-group__wrapper {
    border-right-width: .1rem;
    border-right-color: rgba(0,0,0,.2);
  }
  .v-expansion-panel-header {
    line-height: 1.3rem;
  }

</style>
