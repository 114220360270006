import axios from "@/utils/AxiosInstance.js";
import router from "../../router";

export const accessRoles = {
  namespaced: true,
  state: {
    accessRoleData: {},
    accessRoleDataLoadingStatus: 'notLoading',
    accessRoleDataLoadingError: '',
    accessRoleDataLoadingErrorStatus: false,

    accessRolesList: [],
    accessRolesListLoadingStatus: 'notLoading',
    accessRolesListLoadingError: '',
    accessRolesListLoadingErrorStatus: false,

    associatedAccessRolesList: [],
    associatedAccessRolesListLoadingStatus: 'notLoading',
    associatedAccessRolesListLoadingError: '',
    associatedAccessRolesListLoadingErrorStatus: false,

    accessRoleNewID: '',
    accessRoleNewIDLoadingStatus: 'notLoading',
    accessRoleNewIDLoadingError: '',
    accessRoleNewIDLoadingErrorStatus: false,

    authorizedUserList: [],
    authorizedUserListLoadingStatus: 'notLoading',
    authorizedUserListLoadingError: '',
    authorizedUserListLoadingErrorStatus: false,

    retrieveData: true,

    associatedInsights: [],
    associatedScorecards: [],
  },
  getters: {
    associatedInsights: state => state.associatedInsights,
    associatedScorecards: state => state.associatedScorecards,

    accessRoleData: state => state.accessRoleData,
    accessRoleDataLoadingStatus: state => state.accessRoleDataLoadingStatus,
    accessRoleDataLoadingError: state => state.accessRoleDataLoadingError,
    accessRoleDataLoadingErrorStatus: state => state.accessRoleDataLoadingErrorStatus,

    accessRolesList: state => state.accessRolesList,
    accessRolesListLoadingStatus: state => state.accessRolesListLoadingStatus,
    accessRolesListLoadingError: state => state.accessRolesListLoadingError,
    accessRolesListLoadingErrorStatus: state => state.accessRolesListLoadingErrorStatus,

    associatedAccessRolesList: state => state.associatedAccessRolesList,
    associatedAccessRolesListLoadingStatus: state => state.associatedAccessRolesListLoadingStatus,
    associatedAccessRolesListLoadingError: state => state.associatedAccessRolesListLoadingError,
    associatedAccessRolesListLoadingErrorStatus: state => state.associatedAccessRolesListLoadingErrorStatus,

    accessRoleNewID: state => state.accessRoleNewID,
    accessRoleNewIDLoadingStatus: state => state.accessRoleNewIDLoadingStatus,
    accessRoleNewIDLoadingError: state => state.accessRoleNewIDLoadingError,
    accessRoleNewIDLoadingErrorStatus: state => state.accessRoleNewIDLoadingErrorStatus,

    authorizedUserList: state => state.authorizedUserList,
    authorizedUserListLoadingStatus: state => state.authorizedUserListLoadingStatus,
    authorizedUserListLoadingError: state => state.authorizedUserListLoadingError,
    authorizedUserListLoadingErrorStatus: state => state.authorizedUserListLoadingErrorStatus,

  },
  mutations: {

    SET_ASSOCIATED_INSIGHTS(state, associates) {
      state.associatedInsights = associates
    },
    SET_ASSOCIATED_SCORECARDS(state, associates) {
      state.associatedScorecards = associates
    },



    SET_ACCESS_ROLE_DATA(state, accessRoleData) {
      state.accessRoleData = accessRoleData
    },
    SET_ACCESS_ROLE_DATA_LOADING_STATUS(state, status) {
      state.accessRoleDataLoadingStatus = status
    },
    SET_ACCESS_ROLE_DATA_LOADING_ERROR(state, error) {
      state.accessRoleDataLoadingError = error
    },
    SET_ACCESS_ROLE_DATA_LOADING_ERROR_STATUS(state, status) {
      state.accessRoleDataLoadingErrorStatus = status
    },

    SET_ACCESS_ROLES_LIST(state, accessRolesList) {
      state.accessRolesList = accessRolesList
    },
    SET_ACCESS_ROLES_LIST_LOADING_STATUS(state, status) {
      state.accessRolesListLoadingStatus = status
    },
    SET_ACCESS_ROLES_LIST_LOADING_ERROR(state, error) {
      state.accessRolesListLoadingError = error
    },
    SET_ACCESS_ROLES_LIST_LOADING_ERROR_STATUS(state, status) {
      state.accessRolesListLoadingErrorStatus = status
    },

    SET_ASSOCIATED_ACCESS_ROLES_LIST(state, associatedAccessRolesList) {
      state.associatedAccessRolesList = associatedAccessRolesList
    },
    SET_ASSOCIATED_ACCESS_ROLES_LIST_LOADING_STATUS(state, status) {
      state.associatedAccessRolesListLoadingStatus = status
    },
    SET_ASSOCIATED_ACCESS_ROLES_LIST_LOADING_ERROR(state, error) {
      state.associatedAccessRolesListLoadingError = error
    },
    SET_ASSOCIATED_ACCESS_ROLES_LIST_LOADING_ERROR_STATUS(state, status) {
      state.associatedAccessRolesListLoadingErrorStatus = status
    },

    SET_ACCESS_ROLE_NEW_ID(state, accessRoleNewID) {
      state.accessRoleNewID = accessRoleNewID
    },
    SET_ACCESS_ROLE_NEW_ID_LOADING_STATUS(state, status) {
      state.accessRoleNewIDLoadingStatus = status
    },
    SET_ACCESS_ROLE_NEW_ID_LOADING_ERROR(state, error) {
      state.accessRoleNewIDLoadingError = error
    },
    SET_ACCESS_ROLE_NEW_ID_LOADING_ERROR_STATUS(state, status) {
      state.accessRoleNewIDLoadingErrorStatus = status
    },

    SET_AUTHORIZED_USER_LIST(state, authorizedUserList) {
      state.authorizedUserList = authorizedUserList
    },
    SET_AUTHORIZED_USER_LIST_LOADING_STATUS(state, status) {
      state.authorizedUserListLoadingStatus = status
    },
    SET_AUTHORIZED_USER_LIST_LOADING_ERROR(state, error) {
      state.authorizedUserListLoadingError = error
    },
    SET_AUTHORIZED_USER_LIST_LOADING_ERROR_STATUS(state, status) {
      state.authorizedUserListLoadingErrorStatus = status
    },
  },
  actions: {

    retrieveAccessRolesList(context) {

      if (context.getters.accessRoleData != undefined) {
        if (context.getters.accessRoleData.published != undefined) {
          if (!context.getters.accessRoleData.published) {
            context.commit("SET_ACCESS_ROLES_LIST_LOADING_STATUS", "loading")
          }
        }
      }
      axios
        .get(process.env.VUE_APP_SETUP_ACCESS_ROLES)
        .then(response => {

          let accessRolesList = response.data.roles
          accessRolesList = accessRolesList.map(obj => ({ ...obj, published: true, cancel: false }))
          accessRolesList.sort((a, b) => a.title > b.title && 1 || -1)

          let authorizedUserList = response.data.authorized[0]
          let stateList = context.getters.accessRolesList
          let unpublishedRole = false
          let cancelRole = false

          for (let i = 0; i < stateList.length; i++) {
            if (!stateList[i].published) {
              unpublishedRole = true
            }
            if (stateList[i].cancel) {
              cancelRole = true
              stateList[i].cancel = false
            }
          }

          if (!unpublishedRole || cancelRole) {
            context.commit("SET_AUTHORIZED_USER_LIST", authorizedUserList)
            context.commit("SET_AUTHORIZED_USER_LIST_LOADING_ERROR_STATUS", false)
            context.commit("SET_AUTHORIZED_USER_LIST_LOADING_ERROR", "")
            context.commit("SET_AUTHORIZED_USER_LIST_LOADING_STATUS", "loaded")

            context.commit("SET_ACCESS_ROLES_LIST", accessRolesList)
            context.commit("SET_ACCESS_ROLES_LIST_LOADING_ERROR_STATUS", false)
            context.commit("SET_ACCESS_ROLES_LIST_LOADING_ERROR", "")
            context.commit("SET_ACCESS_ROLES_LIST_LOADING_STATUS", "loaded")
          }
        })
        .catch(function (error) {
          context.commit("SET_ACCESS_ROLES_LIST_LOADING_STATUS", "failed")
          context.commit("SET_ACCESS_ROLES_LIST", [])
          context.commit("SET_ACCESS_ROLES_LIST_LOADING_ERROR_STATUS", true)

          context.commit("SET_AUTHORIZED_USER_LIST_LOADING_STATUS", "failed")
          context.commit("SET_AUTHORIZED_USER_LIST", [])
          context.commit("SET_AUTHORIZED_USER_LIST_LOADING_ERROR_STATUS", true)

          let error_message = JSON.stringify(error)
          context.commit("SET_ACCESS_ROLES_LIST_LOADING_ERROR", 'retrieveAccessRolesList(): ' + error_message)
          context.commit("SET_AUTHORIZED_USER_LIST_LOADING_ERROR", 'retrieveAccessRolesList(): ' + error_message)
        })
    },
    updateAccessRole(context, accessRole) {
      accessRole.cancel = true
      context.commit("SET_ACCESS_ROLE_DATA", accessRole);
    },
    updateAccessRoleClone(context, accessRole) {
      accessRole.published = false
      context.commit("SET_ACCESS_ROLE_DATA", accessRole);
    },
    retrieveAccessRoleData(context, access_role_id) {
      context.commit("SET_ASSOCIATED_INSIGHTS", []);
      context.commit("SET_ASSOCIATED_SCORECARDS", []);
      let storeList = context.getters.accessRolesList
      let accessrole = {}
      storeList.forEach((role, index) => {
        if (role.id == access_role_id) {
          accessrole = role
        }
      });
      if (accessrole.published==undefined || accessrole.published) {

        axios
          .get(process.env.VUE_APP_SETUP_ACCESS_ROLES + access_role_id)
          .then(response => {

            let associates = response.data.associates
            if (associates != undefined) {
              context.commit("SET_ASSOCIATED_INSIGHTS", associates.associated_insights);
              context.commit("SET_ASSOCIATED_SCORECARDS", associates.associated_scorecards);
            }

            context.commit("SET_ACCESS_ROLE_DATA", response.data.role);
            context.commit("SET_ACCESS_ROLE_DATA_LOADING_ERROR_STATUS", false);
            context.commit("SET_ACCESS_ROLE_DATA_LOADING_ERROR", '');
            context.commit("SET_ACCESS_ROLE_DATA_LOADING_STATUS", "loaded");
          })
          .catch(function (error) {
            context.commit("SET_ACCESS_ROLE_DATA_LOADING_STATUS", "failed");
            context.commit("SET_ACCESS_ROLE_DATA", {});
            context.commit("SET_ACCESS_ROLE_DATA_LOADING_ERROR_STATUS", true);
            let error_message = JSON.stringify(error)
            context.commit("SET_ACCESS_ROLE_DATA_LOADING_ERROR", 'retrieveAccessRoleData(): ' + error_message)
          });
      }
    },
    createNewAccessRole(context, params) {

      // make an axios call to create a new "empty" access role
      let aceessRolesList = context.getters.accessRolesList
      let newLabelCount = aceessRolesList.filter(label => label.title.slice(0, 15) == "New Access Role").length
      let titleSuffix = (newLabelCount > 0) ? " (copy " + (newLabelCount + 1) + ")" : ""
      let did = Date.now()
      let ISO = (timeStamp = did) => { return new Date(timeStamp - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T') }
      let dt = ISO()
      let accessRole = {
        id: params.id,
        users: [],
        user_id: params.user_id,
        published: false,
        cancel: true,
        title: "New Access Role" + titleSuffix,
        description: "Description",
        created_at: dt[0] + ' ' + dt[1],
        updated_at: dt[0] + ' ' + dt[1],
      }

      aceessRolesList.push(accessRole)
      aceessRolesList = aceessRolesList.sort((a, b) => a.title > b.title && 1 || -1)

      context.commit("SET_ACCESS_ROLES_LIST", aceessRolesList);
      context.commit("SET_ACCESS_ROLE_DATA", accessRole);
      context.commit("SET_ACCESS_ROLE_DATA_LOADING_ERROR_STATUS", false);
      context.commit("SET_ACCESS_ROLE_DATA_LOADING_ERROR", '');
      context.commit("SET_ACCESS_ROLE_DATA_LOADING_STATUS", "loaded");

    },

    publishAccessRole(context, params) {
      // if published is false do a post - create a new access role
      // else - publish the role
      if (!params.published) {
        axios
          .post(process.env.VUE_APP_SETUP_ACCESS_ROLES, params)
          .then(response => {
            let accessRolesList = context.getters.accessRolesList
            for (let i = 0; i < accessRolesList.length; i++) {
              if (!accessRolesList[i].published) {
                accessRolesList[i].published = true
              }
            }

            context.commit("SET_ACCESS_ROLES_LIST", accessRolesList)
            context.commit("SET_ACCESS_ROLES_LIST_LOADING_STATUS", "notLoading")

            context.commit("SET_ACCESS_ROLE_NEW_ID", response.data.role_id)
            context.commit("SET_ACCESS_ROLE_NEW_ID_LOADING_ERROR_STATUS", false)
            context.commit("SET_ACCESS_ROLE_NEW_ID_LOADING_ERROR", "")
            context.commit("SET_ACCESS_ROLE_NEW_ID_LOADING_STATUS", "loaded")

            context.dispatch('retrieveAccessRolesList')
          })
          .catch(function (error) {
            context.commit("SET_ACCESS_ROLE_NEW_ID_LOADING_STATUS", "failed");
            context.commit("SET_ACCESS_ROLE_NEW_ID", {});
            context.commit("SET_ACCESS_ROLE_NEW_ID_LOADING_ERROR_STATUS", true);

            let error_message = JSON.stringify(error)
            context.commit("SET_ACCESS_ROLE_NEW_ID_LOADING_ERROR", 'publishAccessRole(): ' + error_message)

          });
      } else {

        axios
          .put(process.env.VUE_APP_SETUP_ACCESS_ROLES + params.id, params)
          .then(response => {
            context.dispatch('retrieveAccessRolesList')
          })
          .catch(function (error) {
            let error_message = JSON.stringify(error)
            context.commit("SET_ACCESS_ROLE_DATA_LOADING_ERROR", 'publishAccessRole(): ' + error_message)

          });
      }

    },
    setAccessRolesListLoadingStatus(context, status) {
      context.commit("SET_ACCESS_ROLES_LIST_LOADING_STATUS", status)
    },
    cloneAccessRole(context, params) {
      context.commit("SET_ACCESS_ROLE_DATA_LOADING_STATUS", "loading");
      context.commit("SET_ACCESS_ROLES_LIST_LOADING_STATUS", "loading");

      axios
        .put(process.env.VUE_APP_SETUP_ACCESS_ROLES + params.id + '?action=clone', params)
        .then(response => {

          if (response !== undefined) {
            if (response.status >= 400) {
              alert('The access role (' + params.id + ') was not cloned. The server returned an error: "' + response.data.error.message + ' (' + response.data.error.status + ')"');
            } else {
              router.push('/setup/access-roles/' + response.data.data).catch(err => { })
              context.dispatch('retrieveAccessRolesList')
            }
          } else {
            alert('The dynamic label (' + dynaLabelId + ') definition was not cloned - probably a cross origin request denial');
          }
        })
        .catch(error => {
          // Error
          alert('The access role (' + params.id + ') was not cloned. The server returned an error: "' + error);
        })
        .then(function () {
          // console.log('now, do after-clone (POST) stuff here')
        })
    },

    deleteAccessRole(context, access_role_id) {
      context.commit("SET_ACCESS_ROLES_LIST_LOADING_STATUS", "loading")

      axios
        .delete(process.env.VUE_APP_SETUP_ACCESS_ROLES + access_role_id)
        .then(response => {
          context.dispatch('retrieveAccessRolesList')
        })
        .catch(function (error) {
          let error_message = JSON.stringify(error)
          context.commit("SET_ACCESS_ROLE_DATA_LOADING_ERROR", 'deleteAccessRole(): ' + error_message)
        });


    },

  }
}
