<template>
  <v-container fluid class="pt-4 pb-0">
    <v-row>
      <v-col class="pt-0 pb-0">
        <transition-group name="categoryfade">
          <div
            v-for="category in categories"
            :key="category.id"
            :draggable="canExpand && categories.length > 1"
            @dragstart="startDrag($event, category)"
            @dragend="clearItemDragged()"
          >
            <div class="dropzone-positioning z0">
              <drop-zone
                v-if="
                  dropZoneActive &&
                    !dropZoneNums.includes(category.sort_order - 0.5)
                "
                :dropZoneNumber="category.sort_order - 0.5"
                :sortOrder="itemDragSortOrder"
                :bottom="false"
                class="category-dropzone"
                :type="'category'"
                :scorecardId="Number(scorecardId)"
                :category="category"
                :categories="categories"
                :itemBeingDragged="itemDragging"
                :disabledZone="disableZone"
                @dropCompleteSetPublish="setPublishOn"
              ></drop-zone>
            </div>
            <div class="grab-div">
              <category-display
                :style="
                  canExpand && categories.length > 1 ? 'cursor:move;' : ''
                "
                class="category-size"
                :id="category.id"
                :category="category"
                :expanded="category.expanded"
                :scorecardId="Number(scorecardId)"
                :categoryCount="categories.length"
                :canDragAndDrop="canExpand"
                :properSearches="properSearches"
                :categoriesWithoutSearches="categoriesWithoutSearches"
                :totalWeight="categoriesTotalWeight"
                :categories="categories"
                :mediaType="mediaType"
                @updateSubCategory="subCategoryUpdate"
                @updatesCancelled="updatesCancelled"
                @categoryUpdated="categoryUpdated"
                @updateSubCategoryExpand="updateSubCategoryExpand"
                @deletedCategory="canPublish"
                @updateCategoryExpanded="updateCategoryExpanded"
                @disabledDropZone="disabledDropZone"
                @checkSearches="checkSearches"
                @pushCategoryWithoutSearches="pushCategoryWithoutSearches"
                @setPublishOn="canPublish"
                @checkTitleErrors="shouldDisplayError"
              >
              </category-display>
            </div>
            <v-col class="mb-4">
              <v-btn
                small
                color="blue-grey"
                class="category-button white--text"
                :disabled="hasMaxCategories"
                @click="createNewCategory(category)"
                >ADD CATEGORY</v-btn
              ></v-col
            >
          </div>
        </transition-group>
        <div class="dropzone-positioning">
          <drop-zone
            v-if="lastDropZoneActive"
            :dropZoneNumber="lastCatSortOrder + 0.5"
            :sortOrder="itemDragSortOrder"
            :bottom="true"
            class="category-dropzone"
            :type="'category'"
            :scorecardId="Number(scorecardId)"
            :category="lastCat"
            :categories="categories"
            :itemBeingDragged="itemDragging"
            :disabledZone="disableZone"
            @dropCompleteSetPublish="setPublishOn"
          ></drop-zone>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CategoryDisplay from "./CategoryDisplay.vue";
import DropZone from "./DropZone.vue";

const MAX_CATEGORIES = 40;

export default {
  components: {
    CategoryDisplay,
    DropZone
  },
  data() {
    return {
      itemDragging: "",
      itemDragSortOrder: null,
      disableZone: false,
      dropZoneNums: [],
      categoriesWithoutSearches: [],
      categoriesOrig: [],
      categoriesTotalWeight: 0,
      duplicateTitles: [],
    };
  },
  props: {
    categories: Array,
    scorecardId: String,
    mediaType: String,
  },
  computed: {
    ...mapGetters("resources", ["searchData"]),
    hasMaxCategories() {
      return this.categories.length >= MAX_CATEGORIES;
    },
    properSearches() {
      let properSearches = true;
      if (this.categories) {
        this.categories.forEach((category, index) => {
          if (category.manual_scoring == 0) {
            category.sub_categories.forEach((sub_category, index) => {
              if (sub_category.searches != undefined) {
                if (
                  Number(sub_category.scoring_type) == 0 &&
                  sub_category.searches.length < 1
                ) {
                  this.categoriesWithoutSearches.push(category.id);
                  properSearches = false;
                } else {
                  const indexOfObject = this.categoriesWithoutSearches.findIndex(
                    object => {
                      return object == category.id;
                    }
                  );

                  if (indexOfObject != -1) {
                    this.categoriesWithoutSearches.splice(indexOfObject, 1);
                  }
                }
              }
            });
          }
        });
      }

      if (properSearches) {
        this.categoriesWithoutSearches = [];
      }
      return properSearches;
    },
    dropZoneActive() {
      return this.canExpand && this.categories.length > 1;
    },
    lastDropZoneActive() {
      return (
        this.canExpand &&
        this.categories.length > 1 &&
        !this.dropZoneNums.includes(this.lastCatSortOrder + 0.5)
      );
    },
    lastCatSortOrder() {
      return this.categories[this.categories.length - 1].sort_order;
    },
    lastCat() {
      return this.categories[this.categories.length - 1];
    },
    canExpand() {
      let expand = true;
      if (this.categories.length > 0) {
        this.categories.forEach((category, index) => {
          category.sub_categories.forEach((sub_category, index) => {
            if (sub_category.expanded) {
              expand = false;
              return;
            }
          });
          if (category.expanded) {
            expand = false;
            return;
          }
        });
      }
      return expand;
    },
    canCollapse() {
      let collapse = false;
      if (this.categories.length > 0) {
        this.categories.forEach((category, index) => {
          category.sub_categories.forEach((sub_category, index) => {
            if (sub_category.expanded) {
              collapse = true;
              return;
            }
          });
          if (category.expanded) {
            collapse = true;
            return;
          }
        });
      }
      return collapse;
    }
  },
  methods: {
    shouldDisplayError() {
      const unique = [];
      const dupes = [];
      for (const item of this.categories) {
        const isDuplicate = unique.find(
          obj =>
            obj.category_title.toLowerCase().trim() ==
            item.category_title.toLowerCase().trim()
        );
        if (!isDuplicate) {
          unique.push(item);
        } else {
          dupes.push(item);
        }
      }
      let duplicates = this.categories.filter(a =>
        dupes.some(
          b =>
            a.category_title.toLowerCase().trim() ==
            b.category_title.toLowerCase().trim()
        )
      );
      let duplicateIds = duplicates.map(function(obj) {
        return obj.id;
      });
      this.updateCatTitleError(duplicateIds);
    },
    updateCatTitleError(duplicateIds) {
      let duplicateMatch = [];
      this.categories.forEach(category => {
        duplicateIds.forEach(dupeId => {
          if (dupeId == category.id) {
            category.title_error = true;
            duplicateMatch.push(dupeId);
          }
        });
      });

      this.categories.forEach(category => {
        if (!duplicateMatch.includes(category.id)) {
          category.title_error = false;
        }
      });
    },
    pushCategoryWithoutSearches(id) {
      this.categoriesWithoutSearches.push(id);
    },
    createNewCategory(category) {
      let params = {
        scorecard_id: this.scorecardId,
        category_id: category.id,
        sort_order: category.sort_order,
        default_manual: this.searchData.length < 1
      };
      this.createNewCategoryForScorecard(params);
      this.$emit("setPublish", true);
      this.scrollToPosition();
    },
    scrollToPosition() {
      let id = 0;
      this.categories.forEach((category, index) => {
        if (category.id > id) {
          id = category.id;
        }
      });
      this.$nextTick(() =>
        document.getElementById(id).scrollIntoView({ behavior: "smooth" })
      );
    },
    disabledDropZone(value) {
      this.disableZone = value;
    },
    ...mapActions({
      createNewCategoryForScorecard:
        "scorecardDefinitions/createNewCategoryForScorecard"
    }),
    clearItemDragged() {
      this.itemDragging = "";
      this.dropZoneNums = [];
      this.itemDragSortOrder = null;
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("catID", item.id);
      this.itemDragging = "category";
      this.itemDragSortOrder = item.sort_order;
      this.dropZoneNums.push(item.sort_order - 0.5);
      this.dropZoneNums.push(item.sort_order + 0.5);
    },
    updateSubCategoryExpand(info) {
      let category = this.categories.find(x => x.id == info.id);
      category.sub_categories = info.category_info.sub_categories;
      this.$emit("updateSubCategory");
    },
    updateCategoryExpanded(info) {
      let category = this.categories.find(x => x.id == info.id);
      category.expanded = info.value;
      this.$emit("updateSubCategory");
    },
    categoryUpdated(info) {
      let category = this.categories.find(x => x.id == info.id);
      category.scoring_rule = info.category_info.scoring_rule;
      category.manual_scoring = info.category_info.manual_scoring;
      category.manual_scoring_choice = info.category_info.manual_scoring_choice;
      category.weight = info.category_info.weight;
      category.threshold = info.category_info.threshold;
      category.threshold_count = info.category_info.threshold_count;
      category.threshold_passfail = info.category_info.threshold_passfail;
      category.sub_categories = info.category_info.sub_categories;
      category.category_title = info.category_title;
      category.dynalabels = info.category_info.dynalabels;
      category.cancel = info.category_info.cancel;
      const indexOfObject = this.categoriesWithoutSearches.findIndex(object => {
        return object.id === category.id;
      });
      this.categoriesWithoutSearches.splice(indexOfObject, 1);
      if (category.category_title == "") {
        this.$emit("setPublish", false);
      } else {
        this.$emit("setPublish", true);
      }
      this.calculateTotalWeight();
    },
    canPublish() {
      this.$emit("setPublish", true);
    },
    checkSearches(value) {
      this.$emit("checkSubCategorySearches", value);
    },
    updatesCancelled() {
      this.$emit("updatesCancelled", false);
    },
    subCategoryUpdate() {
      this.$emit("updateSubCategory");
    },
    calculateTotalWeight() {
      this.categoriesTotalWeight = 0;
      this.categories.forEach((category, index) => {
        if (category.scoring_rule != 1) {
          this.categoriesTotalWeight += category.weight;
        }
      });
    },
    setPublishOn() {
      this.canPublish();
    }
  },
  mounted() {
    this.categoriesOrig = this.categories;
    this.calculateTotalWeight();
  },
  watch: {
    categories: "calculateTotalWeight"
  }
};
</script>

<style scoped>
.dropzone-positioning {
  position: relative;
}

.z0 {
  z-index: 0;
}

.grab-div {
  z-index: 100;
}

.category-size {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.category-button {
  z-index: 100;
}

.sidebar {
  text-align: center;
  border-top: 2px dotted rgba(0, 0, 0, 0.2);
  border-right: 2px dotted rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to right, white, rgba(0, 0, 0, 0.05));
}
</style>
